import { termsAndConditions } from "constants/termsAndCondition";

const TermsAndConditions = () => {
  return (
    <section className="privacyPolicy">
      <div className="privacyPolicyContainer">
        {termsAndConditions.map((policy, index) => (
          <>
            {policy?.name || policy?.date ? (
              <div className="commonHeading" key={index}>
                {policy?.name && <h2>{policy?.name}</h2>}
                {policy?.date && <p>{policy?.date}</p>}
              </div>
            ) : null}

            {policy?.para && (
              <div className="commonHeading">
                <p className="commonPolicyPara">{policy?.para}</p>
              </div>
            )}

            {policy?.describe || policy?.title ? (
              <div className="privacyPolicyContents commonPolicy">
                <ul>
                  <li className="commonPolicy">
                    {policy?.title && (
                      <h3 className="commonPolicyHeading">{policy?.title}</h3>
                    )}

                    {policy?.describe && (
                      <p className="commonPolicyPara">{policy?.describe}</p>
                    )}
                  </li>
                </ul>
              </div>
            ) : null}
          </>
        ))}
      </div>
    </section>
  );
};

export default TermsAndConditions;
