import { Link } from "react-router-dom";

import { BASE_IMAGE_URL } from "utils/constants";

import "components/CookiePopUp/cookie.scss";
const Cookie = ({
  setShowCookieModal,
  setShowCookie,
  showCookie,
  cookies,
  setCookies
}) => {
  const handleCookiePopup = () => {
    setShowCookie(false);
    setCookies({
      necessary: true,
      functional: true,
      performance: true
    });
    let tempCookies = {
      necessary: true,
      functional: true,
      performance: true
    };
    localStorage.setItem("acceptAllCookies", JSON.stringify(tempCookies));
    localStorage.removeItem("showCookie");
  };
  return (
    <div className={showCookie ? "cookieMain cookieMainShow" : "cookieMain"}>
      <div className="cookie">
        <div className="cookieLeft">
          <div className="imgBox">
            <img src={`${BASE_IMAGE_URL}/cookie/cookies.svg`} alt="" />
          </div>
          <p>
            We use cookies to improve your experience on our site. By continuing
            to use our site, you accept our use of cookies{" "}
            <Link to="/privacy-policy">
              <span className="readMore">read more</span>
            </Link>
          </p>
        </div>
        <div className="cookieRight">
          <button onClick={() => setShowCookieModal(true)} className="manage">
            Manage Cookies
          </button>
          <button onClick={handleCookiePopup} className="accept">
            Accept All Cookies
          </button>
        </div>
      </div>
    </div>
  );
};
export default Cookie;
