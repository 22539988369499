import { Link } from "react-router-dom";

import Button from "components/Button";
import FadeUp from "components/FadeUp";

import "components/NearBy/nearBy.scss";

const NearBy = ({ ourStoryNearBy, productDetailsNearBy }) => {
  return (
    <section className="nearBy">
      <div
        className={`container nearByContainer ${productDetailsNearBy ? "productDetailsNearBy" : ""}`}
      >
        <div className="nearByBox">
          <FadeUp>
            <div className="nearByBoxHeader">
              <h2>Find us Nearby </h2>
              <p>
                Explore our meticulously curated selection of premium spirits.
              </p>
            </div>
          </FadeUp>

          <FadeUp>
            <Link to={"/visit-us"}>
              <Button>Locate Now</Button>
            </Link>
          </FadeUp>
        </div>
      </div>
    </section>
  );
};

export default NearBy;
