// import arrowButton from "assets/images/home/buttonArrow.svg";
// import blackArrow from "assets/images/home/blackArrowThara.svg";

import { BASE_IMAGE_URL } from "utils/constants";

import "components/Button/button.scss";

const Button = ({
  children,
  className,
  arrowBtn,
  loading,
  visit,
  ...props
}) => {
  const combinedClass = className ? `button ${className}` : "button";
  return (
    <>
      <button
        style={{ fontSize: visit && "18px", whiteSpace: "nowrap" }}
        type="submit"
        className={combinedClass}
        {...props}
      >
        {children}
        {!arrowBtn && !loading && (
          <img
            src={
              className
                ? `${BASE_IMAGE_URL}/home/blackArrowThara.svg`
                : `${BASE_IMAGE_URL}/home/buttonArrow.svg`
            }
            alt="arrowImage"
          />
        )}
        {loading && (
          <div className={visit ? "spinner visitSpinner" : "spinner"}></div>
        )}
      </button>
    </>
  );
};

export default Button;
