import { Link } from "react-router-dom";

import Button from "components/Button/index";
import notFound from "./404Found.svg";
import "components/NotFound/notFound.scss";

const NotFound = () => {
  return (
    <div className="container notFoundContainer">
      <img src={notFound} alt="" />
      <div className="notFoundContent">
        <h4>Uh-oh! This Page is Hungover.</h4>
        <p>
          The page you’re trying to reach is recovering from a wild night out.
          Let us help you find something else!
        </p>
        <Link to="/">
          <Button>Back to Home</Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
