import React, { useEffect, useState, useRef } from "react";
import { PhoneInput } from "react-international-phone";
import Input from "components/Input/index";
import useScreenMobile from "hooks/useScreenMobile";
import { PhoneNumberUtil } from "google-libphonenumber";
import Lottie from "react-lottie-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import messageIcon from "assets/json/message.json";

import { validateForm } from "utils/validation";
import { BASE_IMAGE_URL, Email } from "utils/constants";

import "containers/ContactUs/contactUs.scss";
import "react-international-phone/style.css";

const tabsTitles = ["SEND MESSAGE", "ADD FEEDBACK"];
const MESSAGE_INITIAL_STATE = {
  name: "",
  number: "",
  email: "",
  message: ""
};
const FEEDBACK_INITIAL_STATE = {
  name: "",
  email: "",
  message: ""
};

const ContactUs = () => {
  const getInTouch = `${BASE_IMAGE_URL}/contactUs/getInTouch.webp`;

  const form = useRef();
  const [refs] = useState(
    Array(2)
      .fill(0)
      .map(() => React.createRef())
  );

  const [positions, setPositions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [backToMessage, setBackToMessage] = useState(false);
  const [backToFeedback, setBackToFeedback] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const isMobile = useScreenMobile({ size: 568 });
  const isTab = useScreenMobile({ size: 768 });

  const [message, setMessage] = useState(MESSAGE_INITIAL_STATE);

  const [feedback, setFeedback] = useState(FEEDBACK_INITIAL_STATE);

  const [emptyError, setEmptyError] = useState(false);
  const [errorT, setErrorT] = useState(false);

  useEffect(() => {
    const pos = refs.map((ref) => {
      return {
        width: ref.current?.offsetWidth,
        left: ref.current?.offsetLeft
      };
    });

    setPositions(pos);
  }, [activeTab, refs]);

  const API_URL =
    "https://script.google.com/macros/s/AKfycbxzaecuRCji180K8HZ9oFRWEjk4lcCmndOSWOy8eH9iC0_NedtGlwCav3mdqnwjaEcL/exec";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingState(true);

    const data = activeTab === 0 ? message : feedback;

    const trimmedData = {
      ...data,
      name: data.name.trim(),
      email: data.email.trim(),
      message: data.message.trim()
    };

    if (validateForm(data, isValid)) {
      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(trimmedData),
          mode: "no-cors"
        });

        if (response.ok || response.type === "opaque") {
          if (activeTab === 0) {
            setEmptyError(false);
            setBackToMessage(true);
          } else {
            setErrorT(false);
            setBackToFeedback(true);
          }

          setMessage(MESSAGE_INITIAL_STATE);
          setFeedback(FEEDBACK_INITIAL_STATE);

          setLoadingState(false);
        }
      } catch (error) {
        console.error("Error submitting data", error);
      }
    } else {
      setLoadingState(false);
      if (activeTab === 0) {
        setEmptyError(true);
        setIsValid(false);
      } else {
        setErrorT(true);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (activeTab === 0) {
      setMessage((prevMessage) => ({
        ...prevMessage,
        [name]: value
      }));
    } else {
      setFeedback((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleGoBack = () => {
    if (activeTab === 0) setBackToMessage(false);
    else setBackToFeedback(false);
  };

  const phoneUtil = PhoneNumberUtil.getInstance();
  const [isValid, setIsValid] = useState(false);
  const isPhoneValid = (phone) => {
    setIsValid(
      (() => {
        try {
          let val = phoneUtil.isValidNumber(
            phoneUtil.parseAndKeepRawInput(phone)
          );
          return val;
        } catch (error) {
          return true;
        }
      })()
    );
  };

  useEffect(() => {
    isPhoneValid(message["number"]);
    // eslint-disable-next-line
  }, [message["number"]]);

  return (
    <section className="contactUs">
      <div className="contactUsContainer">
        <div className="contactUsGetInTouch">
          <div className="touchContent">
            <div className="commonHeading getInTouchHeading">
              <h2>Get in Touch with Us</h2>
              <p>
                Questions, feedback, or just want to say hello?
                {isMobile && <br />} Contact us today;
                {isTab && !isMobile && <br />} we're here to help!
              </p>
            </div>
            <div className="contactUsGetInTouchImageBox">
              {/* <img src={getInTouch} alt="" /> */}
              <LazyLoadImage
                src={getInTouch}
                effect="blur"
                placeholderSrc={getInTouch}
              ></LazyLoadImage>
            </div>
          </div>
        </div>
        <div className="contactUsFormBox">
          <div className="formContent">
            {(backToMessage || backToFeedback) && (
              <div className="successMessage">
                <div className="successMessageContent">
                  <Lottie
                    className="lottie"
                    loop={false}
                    animationData={messageIcon}
                    play
                    style={{ width: 84, height: 84 }}
                  />
                  <div className="commonHeading messageHeading">
                    <h2>Thank You!</h2>
                    {backToMessage ? (
                      <p>
                        Your message has been successfully sent.
                        <br /> We'll be in touch shortly.
                      </p>
                    ) : (
                      <p>
                        Your feedback has been successfully submitted. We
                        appreciate your {isMobile && <br />} valuable input.
                      </p>
                    )}
                  </div>
                  <div className="gobackBox">
                    <span onClick={handleGoBack}>Go Back</span>
                  </div>
                </div>
              </div>
            )}
            {!backToMessage && !backToFeedback && (
              <div className="formTabs">
                <div className="tabsBox">
                  {tabsTitles.map((itm, ind) => (
                    <p
                      ref={refs[ind]}
                      onClick={() => setActiveTab(ind)}
                      key={ind}
                      style={{
                        color: activeTab === ind && "#181D25",
                        transition: "all 0.3s linear"
                      }}
                    >
                      {itm}
                    </p>
                  ))}
                  <span
                    className="tabIndicator"
                    style={{
                      width: positions[activeTab]?.width,
                      left: positions[activeTab]?.left
                    }}
                  ></span>
                </div>
              </div>
            )}
            {!backToMessage && !backToFeedback && (
              <form ref={form} onSubmit={handleSubmit} className="form">
                <div className="inputsBox">
                  <div className="errorInputWrapper">
                    <Input
                      value={activeTab === 0 ? message.name : feedback.name}
                      label="Full Name*"
                      name="name"
                      placeholder="Enter your full name"
                      type="text"
                      onChange={handleInputChange}
                    />
                    {activeTab === 0
                      ? message.name.length === 1 &&
                        message.name[0] !== " " &&
                        isNaN(message.name[0]) && (
                          <p
                            style={{
                              visibility:
                                message.name.length === 1 &&
                                message.name[0] !== "."
                                  ? "visible"
                                  : "hidden"
                            }}
                            className="error"
                          >
                            Name must be at least 2 characters long.
                          </p>
                        )
                      : feedback.name.length === 1 &&
                        feedback.name[0] !== " " &&
                        isNaN(feedback.name[0]) && (
                          <p
                            style={{
                              visibility:
                                feedback.name.length === 1 &&
                                feedback.name[0] !== "."
                                  ? "visible"
                                  : "hidden"
                            }}
                            className="error"
                          >
                            Name must be at least 2 characters long.
                          </p>
                        )}
                    {activeTab === 0 ? (
                      (message.name === "" ||
                        (message.name[0] === " " &&
                          message.name.length === 1)) &&
                      emptyError ? (
                        <p
                          style={{
                            visibility:
                              (message.name === "" ||
                                (message.name[0] === " " &&
                                  message.name.length === 1)) &&
                              emptyError
                                ? "visible"
                                : "hidden"
                          }}
                          className="error"
                        >
                          This field is required
                        </p>
                      ) : message.name.length > 50 ? (
                        <p
                          style={{
                            visibility:
                              message.name.length > 50 ? "visible" : "hidden"
                          }}
                          className="error"
                        >
                          Name cannot exceed 50 characters.
                        </p>
                      ) : (
                        <p
                          style={{
                            visibility:
                              /^[a-zA-Z\s]+$/.test(message.name) === false &&
                              message.name.length < 50 &&
                              message.name.length > 0
                                ? "visible"
                                : "hidden"
                          }}
                          className="error"
                        >
                          Name must only contain letters and spaces
                        </p>
                      )
                    ) : (feedback.name === "" ||
                        (feedback.name === " " &&
                          feedback.name.length === 1)) &&
                      errorT ? (
                      <p
                        style={{
                          visibility:
                            (feedback.name === "" ||
                              (feedback.name === " " &&
                                feedback.name.length === 1)) &&
                            errorT
                              ? "visible"
                              : "hidden"
                        }}
                        className="error"
                      >
                        This field is required
                      </p>
                    ) : feedback.name.length > 50 ? (
                      <p
                        style={{
                          visibility:
                            feedback.name.length > 50 ? "visible" : "hidden"
                        }}
                        className="error"
                      >
                        Name cannot exceed 50 characters.
                      </p>
                    ) : (
                      <p
                        style={{
                          visibility:
                            /^[a-zA-Z\s]+$/.test(feedback.name) === false &&
                            feedback.name.length < 50 &&
                            feedback.name.length > 0
                              ? "visible"
                              : "hidden"
                        }}
                        className="error"
                      >
                        Name must only contain letters and spaces
                      </p>
                    )}
                  </div>
                  {activeTab === 0 && (
                    <div className="errorInputWrapper errorInputWrapperPhn">
                      <label>Phone Number*</label>
                      <PhoneInput
                        defaultCountry="us"
                        value={message.number}
                        onChange={(phone) => {
                          isPhoneValid(message.number);
                          setMessage((prev) => ({ ...prev, number: phone }));
                        }}
                        placeholder=""
                      />

                      {!isValid && (
                        <div className="phoneError">
                          Phone Number is not valid
                        </div>
                      )}
                    </div>
                  )}

                  <div className="errorInputWrapper">
                    <Input
                      value={activeTab === 0 ? message.email : feedback.email}
                      label="Email ID*"
                      name="email"
                      placeholder="Enter your email"
                      type="email"
                      onChange={handleInputChange}
                    />
                    {activeTab === 0 ? (
                      message.email === "" && emptyError ? (
                        <p
                          style={{
                            visibility:
                              message.email === "" && emptyError
                                ? "visible"
                                : "hidden"
                          }}
                          className="error"
                        >
                          This field is required
                        </p>
                      ) : (
                        <p
                          style={{
                            visibility:
                              Email.test(message.email) === false &&
                              message.email !== ""
                                ? "visible"
                                : "hidden"
                          }}
                          className="error"
                        >
                          Please enter a valid email address.
                        </p>
                      )
                    ) : feedback.email === "" && errorT ? (
                      <p
                        style={{
                          visibility:
                            feedback.email === "" && errorT
                              ? "visible"
                              : "hidden"
                        }}
                        className="error"
                      >
                        This field is required
                      </p>
                    ) : (
                      <p
                        style={{
                          visibility:
                            Email.test(feedback.email) === false &&
                            feedback.email !== ""
                              ? "visible"
                              : "hidden"
                        }}
                        className="error"
                      >
                        Please enter a valid email address.
                      </p>
                    )}
                  </div>
                  <div className="textareaBox">
                    <label htmlFor="area">
                      {activeTab === 0
                        ? "Message*"
                        : "How was your overall experience with our Vodka?*"}
                    </label>
                    <textarea
                      className="textArea"
                      value={
                        activeTab === 0 ? message.message : feedback.message
                      }
                      id="area"
                      rows="6"
                      cols="50 "
                      name="message"
                      placeholder="Type your message..."
                      onChange={(e) => {
                        if (activeTab === 0) {
                          setMessage((p) => ({
                            ...message,
                            message: e.target.value
                          }));
                        } else {
                          setFeedback((prev) => ({
                            ...prev,
                            message: e.target.value
                          }));
                        }
                      }}
                    ></textarea>
                    <div className="errorMaxBox">
                      {activeTab === 0 ? (
                        message.message === "" && emptyError ? (
                          <p
                            style={{
                              visibility:
                                message.message === "" && emptyError
                                  ? "visible"
                                  : "hidden"
                            }}
                          >
                            This field is required
                          </p>
                        ) : (
                          <p
                            style={{
                              visibility:
                                message.message.length > 250 &&
                                message.message.length !== 0
                                  ? "visible"
                                  : "hidden"
                            }}
                          >
                            Your message must be 250 characters or less.
                          </p>
                        )
                      ) : feedback.message === "" && errorT ? (
                        <p
                          style={{
                            visibility:
                              feedback.message === "" && errorT
                                ? "visible"
                                : "hidden"
                          }}
                        >
                          This field is required
                        </p>
                      ) : (
                        <p
                          style={{
                            visibility:
                              feedback.message.length > 250 &&
                              feedback.message.length !== 0
                                ? "visible"
                                : "hidden"
                          }}
                        >
                          Your message must be 250 characters or less.
                        </p>
                      )}
                      <span>Max: 250 characters</span>
                    </div>
                  </div>
                </div>
                <button type="submit" className="submit">
                  Submit
                  {loadingState && <div className="spinnerWhite"></div>}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
