import { sliceTextUpToColon } from "utils/sliceText";

import { privacyPolicy } from "constants/privacyPolicy";

import "containers/PrivacyPolicy/privacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <section className="privacyPolicy">
      <div className="privacyPolicyContainer">
        {privacyPolicy.map((policy, index) => (
          <>
            {policy?.name || policy?.date ? (
              <div className="commonHeading" key={index}>
                {policy?.name && <h2>{policy?.name}</h2>}
                {policy?.date && <p>{policy?.date}</p>}
              </div>
            ) : null}

            <div className="privacyPolicyContents commonPolicy">
              {policy?.para ? (
                <p className="commonPolicyPara">{policy?.para}</p>
              ) : null}

              {policy?.title ||
              policy?.subHeading ||
              policy?.description ||
              policy?.describe ? (
                <ul>
                  <li className="commonPolicy">
                    {policy?.title && (
                      <h3 className="commonPolicyHeading">{policy?.title}</h3>
                    )}

                    {policy?.subHeading && (
                      <h5 className="data commonPolicyPara subHeading">
                        {policy?.subHeading}
                      </h5>
                    )}

                    {policy?.describe && (
                      <p className="commonPolicyPara">{policy?.describe}</p>
                    )}

                    {policy?.description ? (
                      <>
                        {policy?.description.map((data, index) => (
                          <ul
                            key={index}
                            className={
                              sliceTextUpToColon(data)
                                ? "privacyPolicyList"
                                : "privacyPolicySpecialList"
                            }
                          >
                            <li className="data commonPolicyPara">
                              {sliceTextUpToColon(data)}{" "}
                              <span className="commonPolicyPara">{data}</span>
                            </li>
                          </ul>
                        ))}
                      </>
                    ) : null}
                  </li>
                </ul>
              ) : null}
            </div>
          </>
        ))}
      </div>
    </section>
  );
};

export default PrivacyPolicy;
