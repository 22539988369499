const ProductInformation = ({ cocktail }) => {
  return (
    <section className="productInfo">
      <div className="container productInfoContainer">
        <div className="imageBox">
          <img src={cocktail.img} alt="cocktailImage" />
        </div>
        <div className="detailBox">
          <div className="detailBoxTop">
            <div className="headingBox">
              <h1>{cocktail.name}</h1>
              {cocktail.tagLine && <span>{cocktail.tagLine}</span>}
            </div>
            <p>{cocktail.description1}</p>
            <p>{cocktail.description2}</p>
          </div>
          <div className="detailBoxBottom">
            <div className="BottleImgBox" style={{ maxWidth: "64px" }}>
              <img src={cocktail.icon} alt="bottleImageBottel" />
            </div>
            <div className="nameBox">
              <p>MADE WITH</p>
              <h4>{cocktail.madeWith}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductInformation;
