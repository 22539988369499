import { Email, avoidNumber, avoidSpecialChracters } from "./constants";

const validateForm = (data, isValid) => {
  const { name = "", message = "", email = "" } = data;

  const datName = name.trim();
  const dataMessage = message.trim();
  const dataEmail = email.trim();
  // const dataNumber = number.trim();
  const isNameValid =
    datName.length > 1 &&
    datName.length <= 50 &&
    avoidNumber.test(datName) &&
    avoidSpecialChracters.test(datName);

  const isEmailValid = dataEmail !== "" && Email.test(data["email"]);
  // const isNumberValid =
  //   "number" in data ? dataNumber[0] !== "0" && dataNumber.length === 10 : true;
  const isMessageValid = dataMessage !== "" && dataMessage.length <= 250;

  return isNameValid && isEmailValid && isMessageValid && isValid;
};

export { validateForm };
