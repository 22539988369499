import { readyToDrink } from "constants/readyToDrink";

import FadeUp from "components/FadeUp";

import useScreenMobile from "hooks/useScreenMobile";

import "components/ReadyToDrink/readyToDrink.scss";

const ReadyToDrink = () => {
  const isTab = useScreenMobile({ size: 992 });

  return (
    <section className="readyToDrink">
      <div className="container">
        <FadeUp>
          <div className="commonHeading readyToDrinkContainer">
            <span>launching SOON</span>
            <h2>
              Crafted Convenience:{isTab && <br />} Ready-to-Drink Madiraah
            </h2>
            <p>
              Each Madiraah flavor is a contemporary twist on a timeless
              tradition.
            </p>
          </div>
        </FadeUp>

        <div className="readyToDrinkBoxes">
          {readyToDrink.map((data) => (
            <div key={data.id} className="readyToDrinkBoxesWrapper">
              <div className="readyToDrinkBoxesContent">
                <FadeUp>
                  <h4>{data.name}</h4>
                  <p>{data.details}</p>
                </FadeUp>
              </div>
            </div>
          ))}
        </div>

        <FadeUp>
          <p className="readyToDrinkDescription">
            Inspired by the mythical nectar of the gods, Madiraah is a
            ready-to-drink (RTD){isTab && <br />} spirit range that embodies
            {!isTab && <br />}
            the essence of joy and the art of fine living.
          </p>
        </FadeUp>
      </div>
    </section>
  );
};

export default ReadyToDrink;
