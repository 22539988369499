import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { homeBanner } from "constants/bannerDetails";

import ReadyToDrink from "components/ReadyToDrink";
import Banner from "components/Banner";
import FadeUp from "components/FadeUp";
import NearBy from "components/NearBy";

import OurSpirits from "containers/Home/OurSpirits";
import OurLifeStyle from "containers/Home/OurLifeStyle";
import CocktailRecipe from "containers/Home/CocktailRecipe";
import Experience from "containers/Home/Experience";
import Community from "containers/Home/Community";
import TharraModal from "containers/Home/TharraModal";

import "containers/Home/home.scss";

const Home = ({ setShowCookie }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let hideModal = localStorage.getItem("hideModal");
    let preventModalshowing = localStorage.getItem("preventModalshowing");

    const handleBeforeUnload = (event) => {
      localStorage.removeItem("preventModalshowing");
      scrollTop();
    };

    if (location.pathname === "/") {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    if (hideModal || preventModalshowing) setIsModalOpen(false);
    else setIsModalOpen(true);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Banner
        desktopBannerImage={homeBanner?.desktopBannerImage}
        tabBannerImage={homeBanner?.tabBannerImage}
        mobileBannerImage={homeBanner?.mobileBannerImage}
        heading={homeBanner?.heading}
        paragraph={homeBanner?.paragraph}
        linkName={"our-spirits"}
        button={homeBanner?.button}
      />
      <Experience />
      <OurSpirits />
      <ReadyToDrink />
      <CocktailRecipe />

      <section className="review">
        <div className="container">
          <FadeUp>
            <div className="commonHeading ourCustomerHeading">
              <h2>Cheers to Our Happy Customers</h2>
              <p>
                Discover why our customers love Tharra. Read their reviews and
                see how our spirits elevate their experiences.
              </p>
            </div>
          </FadeUp>

          <div
            className="elfsight-app-59473099-7195-4354-a9c4-11eeae1add4f"
            data-elfsight-app-lazy
          ></div>
        </div>
      </section>
      <Community />
      <OurLifeStyle />
      <NearBy />
      <TharraModal
        setShowCookie={setShowCookie}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default Home;
