import FadeUp from "components/FadeUp/index";
import { BASE_IMAGE_URL } from "utils/constants";

import useScreenMobile from "hooks/useScreenMobile";

const ReadyToDrink = () => {
  const madiraDesktopLogo = `${BASE_IMAGE_URL}/ourProducts/madiraImg.webp`;
  const madiraTabletLogo = `${BASE_IMAGE_URL}/ourProducts/madiraImgTablet.webp`;
  const madiraMobileLogo = `${BASE_IMAGE_URL}/ourProducts/madiraImgMobile.webp`;
  const isMobile = useScreenMobile({ size: 568 });
  const isTablet = useScreenMobile({ size: 768 });

  return (
    <section className="drink">
      <div className="container drinkContainer">
        <FadeUp>
          <div className="drinkData">
            <div className="drinkDataComingSoon">
              <h2>
                Coming Soon:
                <span>Ready-to-Drink Madiraah</span>
              </h2>

              <span className="drinkDataPara">
                Madiraah (Madira) – where legends and indulgence intertwine.
              </span>
            </div>
            <div className="drinkDescription">
              <span className="drinkDataPara">
                Inspired by the mythical nectar of the gods, Madiraah is a
                ready-to-drink (RTD) spirit range that embodies the essence of
                joy and the art of fine living.
              </span>

              <span className="drinkDataPara">
                Each Madiraah flavor - be it the earthy sweetness of Bael, the
                tangy zest of Jamun,or the spice-infused thrill of Paani - is a
                contemporary twist on a timeless tradition.
              </span>
            </div>
          </div>
        </FadeUp>

        <FadeUp>
          <div className="drinkLogo">
            <img
              src={
                isMobile
                  ? madiraMobileLogo
                  : isTablet
                    ? madiraTabletLogo
                    : madiraDesktopLogo
              }
              alt="drinkingImage"
            />
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default ReadyToDrink;
