import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { BASE_IMAGE_URL } from "utils/constants";

import useScreenMobile from "hooks/useScreenMobile";

import { ourSpirit, navBarMobile } from "constants/navBarMobile";

import { useNavigate } from "react-router-dom";
import "components/Header/header.scss";

const Header = () => {
  const [closeButton, setCloseButton] = useState(false);
  const [navBarOption, setNavBarOption] = useState(false);
  // const [navBarOptionDesktop, setNavBarOptionDesktop] = useState(true);
  const [position, setPosition] = useState(0);
  const [hideHeader, setHideHeader] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const [sideBarPosition, setSideBarPosition] = useState("");
  const [screenType, setScreenType] = useState();
  const ulRef = useRef(null);
  const isTab = useScreenMobile({ size: 992 });
  const isMobile = useScreenMobile({ size: 760 });
  const cls = hideHeader ? "" : "hidden";

  const navigate = useNavigate();
  const location = useLocation();

  const websiteLogo = `${BASE_IMAGE_URL}/header/websiteLogo.svg`;
  const downArrow = `${BASE_IMAGE_URL}/header/downArrow.svg`;
  const humburger = `${BASE_IMAGE_URL}/header/humbuger.svg`;
  const websiteLogoMobile = `${BASE_IMAGE_URL}/header/websiteLogoMobile.svg`;
  const locationPin = `${BASE_IMAGE_URL}/header/locationPin.svg`;
  const crossButton = `${BASE_IMAGE_URL}/header/closeButton.svg`;

  useEffect(() => {
    const handleScroll = () => {
      const moving = window.pageYOffset;
      if (moving > 100) {
        setHideHeader(position > moving);
        setSideBarPosition("moveTop");
      } else {
        setHideHeader(true);
        setSideBarPosition("");
      }
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    const handleScrollSticky = () => {
      const ulElement = ulRef.current;
      const offsetTop = ulElement?.offsetTop;
      const isSticky = window.scrollY > offsetTop;
      setIsSticky(isSticky);
    };
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScrollSticky);
    return () => {
      window.removeEventListener("scroll", handleScrollSticky);
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (closeButton) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    setNavBarOption(false);
  }, [closeButton]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setNavBarOptionDesktop(false);
  //   }, 10000);
  //   setNavBarOptionDesktop(true);
  //   setNavBarOptionDesktop(false);
  // }, [navigate]);

  useEffect(() => {
    setCloseButton(false);
  }, [isTab, isMobile]);

  useEffect(() => {
    if (isTab) {
      setScreenType("tab");
    } else if (isMobile) {
      setScreenType("mobile");
    } else {
      setScreenType("desktop");
    }
  }, [isTab, isMobile]);

  const handleHome = () => {
    navigate("/");
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const closeNavBar = () => {
    setCloseButton(false);
  };

  const handleClickOutside = (event) => {
    const exportBtn = event.target.closest("#navBarDropDown");
    if (
      !exportBtn &&
      !event.target.closest("#crossButton") &&
      !event.target.closest("#humBurgerIcon")
    ) {
      setCloseButton(false);
    }
  };

  return (
    <header className={`header ${sideBarPosition}`}>
      <div
        className={
          isSticky
            ? "headerContainer"
            : isTab
              ? cls
                ? "tabHeaderContainer hidden"
                : "tabHeaderContainer "
              : "container headerContainer"
        }
      >
        {isTab ? (
          <>
            <div className="headerMobileContainer">
              {closeButton ? (
                <img
                  src={crossButton}
                  alt="crossButton"
                  className="crossButton"
                  id="crossButton"
                  onClick={() => {
                    setCloseButton(!closeButton);
                  }}
                />
              ) : (
                <img
                  src={humburger}
                  alt="humBurgerIcon"
                  id="humBurgerIcon"
                  onClick={() => {
                    setCloseButton(!closeButton);
                  }}
                />
              )}
              <img
                className="websiteLogo"
                onClick={handleHome}
                src={websiteLogoMobile}
                alt="websiteLogo"
              />
              <Link to={"/visit-us"}>
                <img src={locationPin} alt="locationPin" />
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="headerImage">
              {screenType && (
                <img
                  className="websiteLogo"
                  src={screenType === "mobile" ? humburger : websiteLogo}
                  alt="websiteLogo"
                  onClick={handleHome}
                />
              )}
            </div>
            <ul
              ref={ulRef}
              id="navBarLinks"
              className={`navBarLinks ${isSticky ? "sticky" : ""}`}
            >
              <li
                className={`${location.pathname === "/" ? "navBarLinksActive" : ""}`}
              >
                <Link to="/" className="navBarOption">
                  HOME
                </Link>
              </li>
              <li
                className={`${location.pathname === "/our-story" ? "navBarLinksActive" : ""}`}
              >
                <Link to="/our-story" className="navBarOption">
                  OUR STORY
                </Link>
              </li>
              <li
                // className={`${navBarOptionDesktop ? "navBarHover navBarHoverCheck" : "navBarUnHover"} ${location.pathname === "/our-spirits" ? "navBarLinksActive" : ""}`}
                className={`navBarHover navBarHoverCheck ${location.pathname === "/our-spirits" ? "navBarLinksActive" : ""}`}
                // onMouseEnter={() => {
                //   setNavBarOptionDesktop(true);
                // }}
                // onMouseLeave={() => setNavBarOptionDesktop(false)}
              >
                <Link
                  to="/our-spirits"
                  className="navBarOption navBarOptionSpirits"
                >
                  OUR SPIRITS
                </Link>
                <img
                  className="rotateOnHover "
                  src={downArrow}
                  alt="downArror"
                />

                {/* {navBarOptionDesktop && (
                  <div
                    className={`navBarDropDownOption ${isSticky ? "navBarDropDownOptionSticky" : ""}`}
                    onMouseEnter={() => {
                      setNavBarOptionDesktop(true);
                    }}
                    onMouseLeave={() => {
                      setNavBarOptionDesktop(false);
                    }}
                  >
                    <div className="spirtsDropDown">
                      <div className="commonHeading spitsHeading">
                        <h2>Explore our spirits</h2>
                        <p>
                          Discover the exquisite range of Tharra spirits,
                          crafted for exceptional taste experiences
                        </p>
                      </div>
                      <div className="commonHeading spitsDescription">
                        <p>Explore products at:</p>
                        <h3>
                          Introductory pricing of just <span>$34.99</span>
                        </h3>
                      </div>
                    </div>
                    <div className="navBarImageContainer">
                      {ourSpirit.map(
                        (item, index) =>
                          item.navBarLinkName !== "Go back" && (
                            <Link to={item.link}>
                              <div
                                className="navBarImages"
                                key={index}
                                onClick={scrollTop}
                              >
                                {item.image && (
                                  <div className="navBarImagesImg">
                                    <img src={item?.image} alt="ourSpirit" />
                                  </div>
                                )}
                                <p>{item.navBarLinkName}</p>
                              </div>
                            </Link>
                          )
                      )}
                    </div>
                  </div>
                )} */}
                <div
                  // className={`navBarDropDownOption ${isSticky ? "navBarDropDownOptionSticky" : ""} ${navBarOptionDesktop ? "navBarDropDownOptionStickyActive" : ""}`}
                  className={`navBarDropDownOption ${isSticky ? "navBarDropDownOptionSticky" : ""} navBarDropDownOptionStickyActive`}
                  // onMouseEnter={() => {
                  //   setNavBarOptionDesktop(true);
                  // }}
                  // onMouseLeave={() => {
                  //   setNavBarOptionDesktop(false);
                  // }}
                >
                  <div className="spirtsDropDown">
                    <div className="commonHeading spitsHeading">
                      <h2>Explore our spirits</h2>
                      <p>
                        Discover the exquisite range of Tharra spirits, crafted
                        for exceptional taste experiences
                      </p>
                    </div>
                    {/* <div className="commonHeading spitsDescription">
                      <p>Explore products at:</p>
                      <h3>
                        Introductory pricing of just <span>$34.99</span>
                      </h3>
                    </div> */}
                  </div>
                  <div className="navBarImageContainer">
                    {ourSpirit.map(
                      (item, index) =>
                        item.navBarLinkName !== "Go back" && (
                          <Link to={item.link} key={index}>
                            <div className="navBarImages" onClick={scrollTop}>
                              {item.image && (
                                <div className="navBarImagesImg">
                                  <img src={item?.image} alt="ourSpirit" />
                                </div>
                              )}
                              <p>{item.navBarLinkName}</p>
                            </div>
                          </Link>
                        )
                    )}
                  </div>
                </div>
              </li>
              <li
                className={`${location.pathname === "/our-cocktail" ? "navBarLinksActive" : ""}`}
              >
                <Link to="/our-cocktail" className="navBarOption">
                  OUR COCKTAILS
                </Link>
              </li>
              <li
                className={`${location.pathname === "/contact-us" ? "navBarLinksActive" : ""}`}
              >
                <Link to="/contact-us" className="navBarOption">
                  CONTACT US
                </Link>
              </li>
              <li
                className={`${location.pathname === "/visit-us" ? "navBarLinksActive" : ""}`}
              >
                <Link to="/visit-us" className="navBarOption">
                  FIND US NEARBY
                </Link>
              </li>
            </ul>
          </>
        )}
      </div>

      <div
        className={`${isMobile ? "navBarMobile navBarDropDown" : "navBarDropDown"} ${closeButton ? "navBarDropDownActive" : ""}`}
        id="navBarDropDown"
      >
        <ul className="navBarLinks">
          {navBarOption ? (
            <>
              {ourSpirit.map((item, index) => (
                <ul key={index}>
                  <li
                    onClick={() => {
                      index === 0 && setNavBarOption(!navBarOption);
                    }}
                    className={item?.className}
                  >
                    {index === 0 && (
                      <img
                        src={downArrow}
                        alt="rightArrow"
                        className="leftArrow"
                      />
                    )}

                    <Link
                      to={item.link}
                      className={
                        item.navBarLinkName === "Go back"
                          ? "navBarOptionMobile navBarGoBack"
                          : "navBarOptionMobile"
                      }
                      onClick={item.navBarLinkName !== "Go back" && closeNavBar}
                      id="navBarDropDown"
                    >
                      {item.navBarLinkName}
                    </Link>
                  </li>
                </ul>
              ))}
            </>
          ) : (
            <>
              {navBarMobile.map((item, index) => (
                <ul key={index}>
                  <li
                    onClick={() => {
                      index === 2 && setNavBarOption(!navBarOption);
                    }}
                  >
                    <Link
                      to={item.link}
                      className="navBarOptionMobile"
                      onClick={() => {
                        closeNavBar();
                        scrollTop();
                      }}
                    >
                      {item.navBarLinkName}
                    </Link>
                    {index === 2 && (
                      <img
                        src={downArrow}
                        alt="rightArrow"
                        className="downArrow"
                      />
                    )}
                  </li>
                </ul>
              ))}
            </>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Header;
