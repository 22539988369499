import FadeUp from "components/FadeUp";
import { BASE_IMAGE_URL } from "utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import useScreenMobile from "hooks/useScreenMobile";

import "containers/OurStory/ourStory.scss";

const BirthTharra = () => {
  const birthTharra = `${BASE_IMAGE_URL}/birthTharra/birthTharra.webp`;

  const isTab = useScreenMobile({ size: 992 });
  return (
    <section className="birthTharra">
      <div className="container birthTharraContainer">
        {isTab ? (
          <>
            <div className="birthTharraContent">
              <div className="birthImageContainer">
                <FadeUp>
                  <div className="commonHeading birthTharraHeading">
                    <h2>Birth of Tharra Company</h2>
                    <p>
                      Tharra's inception: a fusion of vision, passion, and
                      craftsmanship.
                    </p>
                  </div>
                </FadeUp>
                <div className="birthTharraImage">
                  <LazyLoadImage
                    src={birthTharra}
                    effect="blur"
                    placeholderSrc={birthTharra}
                  ></LazyLoadImage>
                </div>
                <FadeUp>
                  <div className=" commonHeading birthTharraDescription ">
                    <p>
                      In the realm of spirits, where tradition meets innovation,
                      Tharra Company emerges as a pioneer, crafting an alchemy
                      of tastes that transcends the ordinary. We are artisans of
                      flavor, drawing from the rich tapestry of India's culinary
                      heritage to create an exquisite line of spirits infused
                      with the subcontinent's most iconic and beloved
                      ingredients. Our unique offerings—Jamun, Paani, and
                      Bael—represent more than just exceptional beverages; they
                      are an invitation to explore a world of untapped
                      sensations and undiscovered experiences.
                    </p>
                    <span>
                      Meticulous experimentation and relentless pursuit of
                      perfection culminated in a symphony of flavors that will
                      tantalize your taste buds.
                    </span>
                  </div>
                </FadeUp>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="birthTharraContent">
              <FadeUp>
                <div className="commonHeading birthTharraHeading">
                  <h2>Birth of Tharra Company</h2>
                  <p>
                    Tharra's inception: a fusion of vision, passion, and
                    craftsmanship.
                  </p>
                </div>
              </FadeUp>
              <FadeUp>
                <div className=" commonHeading birthTharraDescription ">
                  <p>
                    In the realm of spirits, where tradition meets innovation,
                    Tharra Company emerges as a pioneer, crafting an alchemy of
                    tastes that transcends the ordinary. We are artisans of
                    flavor, drawing from the rich tapestry of India's culinary
                    heritage to create an exquisite line of spirits infused with
                    the subcontinent's most iconic and beloved ingredients. Our
                    unique offerings—Jamun, Paani, and Bael—represent more than
                    just exceptional beverages; they are an invitation to
                    explore a world of untapped sensations and undiscovered
                    experiences.
                  </p>
                  <span>
                    Meticulous experimentation and relentless pursuit of
                    perfection culminated in a symphony of flavors that will
                    tantalize your taste buds.
                  </span>
                </div>
              </FadeUp>
            </div>
            <div className="birthTharraImage">
              <LazyLoadImage
                src={birthTharra}
                effect="blur"
                placeholderSrc={birthTharra}
              ></LazyLoadImage>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default BirthTharra;
