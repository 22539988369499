import { Link } from "react-router-dom";
import Button from "components/Button";
import { BASE_IMAGE_URL } from "utils/constants";

import useScreenMobile from "hooks/useScreenMobile";

import "containers/ProductCard/productCard.scss";
import { smoothNavigation } from "utils/scrollBehaviour";

const TheEssence = () => {
  const isTab = useScreenMobile({ size: 992 });
  const isMobile = useScreenMobile({ size: 568 });

  const EssenceImg = `${BASE_IMAGE_URL}/cocktailDetails/essenceDesktopImg.webp`;
  const EssenceImgTab = `${BASE_IMAGE_URL}/cocktailDetails/essenceTabImg.webp`;
  const EssenceImgMobile = `${BASE_IMAGE_URL}/cocktailDetails/essenceMobileImg.webp`;

  return (
    <section className="theEssence">
      <div className="theEssenceDetails">
        <div className="commonHeading theEssenceDetailsTitle">
          <h2>Discover the Essence of Exotic Indulgence </h2>
          <p>
            Explore the exotic allure of our Jamun-infused vodka. Elevate your
            cocktails with rich flavors. Click to discover more!
          </p>
        </div>
        <Link to={"/our-spirits/#ourProduct"}>
          <div
            className="exploreBtn"
            onClick={() => {
              setTimeout(() => {
                smoothNavigation("ourProduct", { ourProduct: 59 });
              }, 300);
            }}
          >
            <Button>Explore Products</Button>
          </div>
        </Link>
      </div>
      <div className="theEssenceImg">
        {isMobile ? (
          <img src={EssenceImgMobile} alt="EssenceImgMobile" />
        ) : (
          <img src={isTab ? EssenceImgTab : EssenceImg} alt="EssenceImg" />
        )}
      </div>
    </section>
  );
};

export default TheEssence;
