import { useEffect, useState } from "react";
import CocktailCard from "components/CocktailCard";
import Button from "components/Button";

import useScreenMobile from "hooks/useScreenMobile";

import { cocktailListData } from "constants/cocktailList";

import "containers/OurCocktails/ourCocktails.scss";

const CocktailsList = () => {
  const [activeTitle, setActiveTitle] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [displayCount, setDisplayCount] = useState(8);

  const isTab = useScreenMobile({ size: 992 });
  const isMobile = useScreenMobile({ size: 760 });

  const titles = ["All", "Jamun", "Paani", "Potato", "Bael"];

  useEffect(() => {
    changeData(titles[activeTitle]);
    setDisplayCount(isTab || isMobile ? 4 : 8);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTitle, isTab, isMobile]);

  const changeData = (category) => {
    if (category === "All") {
      setFilterData(cocktailListData);
    } else {
      const filteredData = cocktailListData.filter(
        (itm) => itm.category === category
      );
      setFilterData(filteredData);
    }
  };

  const handleLoadMore = () => {
    setDisplayCount((prevCount) => prevCount + (isTab || isMobile ? 4 : 8));
  };

  return (
    <section className="cocktailsList" id="cocktailsList">
      <div className="cocktailsListTitles">
        <ul id="ourCocktail">
          {titles.map((title, id) => (
            <li
              onClick={() => setActiveTitle(id)}
              className={activeTitle === id ? "cardTitleActive" : ""}
              key={id}
            >
              {title}
            </li>
          ))}
        </ul>
      </div>

      <div className="container">
        <div className="cocktailsListSection">
          {filterData.length > 0 ? (
            filterData
              .slice(0, displayCount)
              .map((data) => <CocktailCard key={data.id} data={data} />)
          ) : (
            <h2 className="noFound">No Cocktail Found</h2>
          )}
        </div>

        {filterData.length > displayCount && (
          <div className="buttonBox">
            <Button onClick={handleLoadMore} className="btn">
              Load More
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default CocktailsList;
