export const footerCocktails = [
  {
    footerCocktailsName: "Tharra Bael Bloom",
    footerLink: "tharra-bael-bloom"
  },
  {
    footerCocktailsName: "Tharra Bael Paani Punch",
    footerLink: "tharra-bael-paani-punch"
  },
  {
    footerCocktailsName: "Tharra Jamun Fizzz",
    footerLink: "tharra-jamun-fizzz"
  },
  {
    footerCocktailsName: "Tharra Paani Punch",
    footerLink: "tharra-paani-punch"
  },
  {
    footerCocktailsName: "Tharra Paani Surprise",
    footerLink: "tharra-paani-surprise"
  },

  {
    footerCocktailsName: "Potato Basil Bliss",
    footerLink: "potato-basil-bliss"
  },
  {
    footerCocktailsName: "Tharra Jamun Ki Dukaan",
    footerLink: "tharra-jamun-ki-dukaan"
  },
  {
    footerCocktailsName: "See All"
  }
];

export const footerOurSpirits = [
  {
    footerSpiritName: "Tharra Jamun",
    footerLink: "tharra-jamun"
  },
  {
    footerSpiritName: "Tharra Paani",
    footerLink: "tharra-paani"
  },
  {
    footerSpiritName: "Tharra Bael",
    footerLink: "tharra-bael"
  },
  {
    footerSpiritName: "Potato Vodka",
    footerLink: "potato-vodka"
  }
];

export const footerPages = [
  {
    pageName: "Our Story",
    link: "/our-story"
  },
  {
    pageName: "Find Us Nearby",
    link: "/visit-us"
  }
];
