// import homeDesktopBanner from "assets/images/banner/homeDesktop.webp";
// import homeTabBanner from "assets/images/banner/homeTablet.webp";
// import homeMobileBanner from "assets/images/banner/homeMobile.webp";

// import ourSpiritDesktopBanner from "assets/images/banner/ourSpiritDesktop.webp";
// import ourSpiritTabletBanner from "assets/images/banner/ourSpiritTablet.webp";
// import ourSpiritMobileBanner from "assets/images/banner/ourSpiritMobile.webp";

// import ourStoryBanner from "assets/images/banner/ourStoryDesktop.webp";
// import ourStoryTabletBanner from "assets/images/banner/ourStoryTablet.webp";
// import ourStoryMobileBanner from "assets/images/banner/ourStoryMobile.webp";

// import ourCocktailsBannerVideo from "assets/video/cocktailBanner.mp4";

import { BASE_IMAGE_URL, BASE_VIDEO_URL } from "utils/constants";

export const homeBanner = {
  heading: "Experience the soul of India in every Sip",
  paragraph:
    "Raise your glass and join us on an exquisitely delicious journey ",
  desktopBannerImage: `${BASE_IMAGE_URL}/banner/homeDesktop.webp`,
  tabBannerImage: `${BASE_IMAGE_URL}/banner/homeTablet.webp`,
  mobileBannerImage: `${BASE_IMAGE_URL}/banner/homeMobile.webp`,
  button: "Explore Our Spirits"
};

export const ourSpirits = {
  heading: "Introducing Indian Inspired Vodka Collection",
  paragraph:
    "Savor premium vodka fused with traditional Indian spices for unique flavor.",
  desktopBannerImage: `${BASE_IMAGE_URL}/banner/ourSpiritDesktop.webp`,
  tabBannerImage: `${BASE_IMAGE_URL}/banner/ourSpiritTablet.webp`,
  mobileBannerImage: `${BASE_IMAGE_URL}/banner/ourSpiritMobile.webp`
};

export const ourCocktails = {
  heading: "Indulge in Exquisite Mixology ",
  paragraph:
    "Discover crafted cocktails, blending classics with bold innovations for the best experiences.",
  desktopBannerVideo: `${BASE_VIDEO_URL}/cocktailBanner.mp4`,
  tabBannerVideo: `${BASE_VIDEO_URL}/cocktailBanner.mp4`,
  mobileBannerVideo: `${BASE_VIDEO_URL}/cocktailBanner.mp4`
};

export const ourStory = {
  heading: "Crafting Delicious Flavors, Creating Happy Memories!",
  paragraph: "Passion, precision, excellence. Discover our journey.",
  desktopBannerImage: `${BASE_IMAGE_URL}/banner/ourStoryDesktop.webp`,

  tabBannerImage: `${BASE_IMAGE_URL}/banner/ourStoryTablet.webp`,
  mobileBannerImage: `${BASE_IMAGE_URL}/banner/ourStoryMobile.webp`
};
