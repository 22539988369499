import FadeUp from "components/FadeUp/index";

import useScreenMobile from "hooks/useScreenMobile";

import "containers/Home/home.scss";
import { BASE_IMAGE_URL } from "utils/constants";

const OurLifeStyle = () => {
  const styleShirtsDesktop = `${BASE_IMAGE_URL}/home/jackets.webp`;
  const styleShirtsMobile = `${BASE_IMAGE_URL}/home/resshirt.webp`;
  const bagToolsDesktop = `${BASE_IMAGE_URL}/home/openBagToolsDesktop.webp`;
  const bagToolsMobile = `${BASE_IMAGE_URL}/home/openBagToolsMobile.webp`;

  const isMobile = useScreenMobile({ size: 768 });

  return (
    <section className="lifeStyle">
      <div className="container lifeStyleContainer">
        <FadeUp>
          <div className="commonHeading heading">
            <h2>Elevate your lifestyle with tharra</h2>
            <p>Tharra's Upcoming Bar Accessories and Apparel Launches Await!</p>
          </div>
        </FadeUp>

        <div className="lifeStyleBoxes">
          <div className="Box">
            <FadeUp>
              <div className="headingBox">
                <h3>Apparel Line</h3>
                <span>COMING SOON</span>
              </div>
            </FadeUp>
            <p>
              Bold flavors meet bold living. Introducing ethically sourced
              apparel, reflecting our vibrant spirit.
            </p>
            <div className="imgBox shirtImgBox">
              <img
                src={!isMobile ? styleShirtsDesktop : styleShirtsMobile}
                alt="shirtImage"
              />
            </div>
          </div>
          <div className="Box">
            <div className="headingBox">
              <h3>Bar Accessories</h3>
              <span>COMING SOON</span>
            </div>
            <p>
              Elevate your Tharra experience with our curated barware
              collection—precision tools for crafting and celebrating. Cheers!
            </p>
            <div className="imgBox bagImgBox">
              <img
                src={isMobile ? bagToolsMobile : bagToolsDesktop}
                alt="bagImage"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurLifeStyle;
