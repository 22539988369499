import FadeUp from "components/FadeUp";
import "react-lazy-load-image-component/src/effects/blur.css";

import useScreenMobile from "hooks/useScreenMobile";
import { BASE_IMAGE_URL } from "utils/constants";

const FoundersJourney = () => {
  const founderJourney = `${BASE_IMAGE_URL}/ourStory/founder.webp`;

  const isMobile = useScreenMobile({ size: 992 });

  return (
    <section className="ourStory">
      <div className="container" style={{ padding: "20px 60px" }}>
        <div className="ourStoryImg">
          {!isMobile && (
            <img
              src={founderJourney}
              alt="founderImage"
              className="founderImage"
            />
          )}
        </div>

        <div className="ourStoryDescription">
          <FadeUp>
            <h2>Founder&apos;s Story</h2>
          </FadeUp>

          {isMobile && (
            <div
              className={`ourStoryImg ${isMobile && "ourStoryDescriptionImg"}`}
            >
              {/* <LazyLoadImage
                src={founderJourney}
                effect="blur"
                placeholderSrc={founderJourney}
              ></LazyLoadImage> */}
              <img src={founderJourney} alt="" />
            </div>
          )}
          <FadeUp>
            <p>
              Tharra Company was born out of a deep love for India's rich and
              vibrant flavors. My childhood summers were a symphony of tastes:
              the sweet, tangy burst of freshly picked Jamun from the neighbor's
              tree, the explosive joy of India&apos;s favorite street food Paani
              Puri, and the comforting warmth of my mother's homemade Bael
              chutney.
            </p>

            <p>
              These cherished memories inspired me to bottle the essence of
              India. Tharra is a tribute to our heritage, beautifully reimagined
              into modern, exquisite flavors.
            </p>
          </FadeUp>
        </div>
      </div>
    </section>
  );
};

export default FoundersJourney;
