import { ourStory } from "constants/bannerDetails";

import Banner from "components/Banner";
import NearBy from "components/NearBy";

import Discover from "containers/OurStory/Discover";
import OurStoryPlaces from "containers/OurStory/OurStoryPlaces";
import FoundersJourney from "containers/OurStory/FoundersJourney";
import BirthTharra from "containers/OurStory/BirthTharra";

import "containers/OurStory/ourStory.scss";

const OurStory = () => {
  return (
    <>
      <Banner
        desktopBannerImage={ourStory?.desktopBannerImage}
        tabBannerImage={ourStory?.tabBannerImage}
        mobileBannerImage={ourStory?.mobileBannerImage}
        heading={ourStory?.heading}
        //   paragraph={ourStory?.paragraph}
        button={ourStory?.button}
        bannerHeading="ourStoryHeading"
      />
      <BirthTharra />
      <OurStoryPlaces />
      <Discover />
      <FoundersJourney />
      <NearBy />
    </>
  );
};

export default OurStory;
