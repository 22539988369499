import Marquee from "react-fast-marquee";
import { BASE_IMAGE_URL } from "utils/constants";

import "components/Slider/myslider.scss";

const Myslider = ({ direction }) => {
  const rec = `${BASE_IMAGE_URL}/home/slider1.webp`;
  const rec2 = `${BASE_IMAGE_URL}/home/slider2.webp`;
  const rec3 = `${BASE_IMAGE_URL}/home/slider3.webp`;
  const rec4 = `${BASE_IMAGE_URL}/home/slider4.webp`;
  const rec5 = `${BASE_IMAGE_URL}/home/slider5.webp`;
  const rec6 = `${BASE_IMAGE_URL}/home/slider6.webp`;
  const rec7 = `${BASE_IMAGE_URL}/home/slider7.webp`;
  const rec8 = `${BASE_IMAGE_URL}/home/slider8.webp`;
  const rec9 = `${BASE_IMAGE_URL}/home/slider9.webp`;
  const rec10 = `${BASE_IMAGE_URL}/home/slider10.webp`;
  const rec11 = `${BASE_IMAGE_URL}/home/slider11.webp`;
  const rec12 = `${BASE_IMAGE_URL}/home/slider12.webp`;
  const rec13 = `${BASE_IMAGE_URL}/home/slider13.webp`;
  const rec14 = `${BASE_IMAGE_URL}/home/slider14.webp`;
  const rec15 = `${BASE_IMAGE_URL}/home/slider15.webp`;
  const rec16 = `${BASE_IMAGE_URL}/home/slider16.webp`;
  const rec17 = `${BASE_IMAGE_URL}/home/slider17.webp`;
  const rec18 = `${BASE_IMAGE_URL}/home/slider18.webp`;
  const rec19 = `${BASE_IMAGE_URL}/home/slider19.webp`;
  const rec20 = `${BASE_IMAGE_URL}/home/slider20.webp`;
  let array = [rec, rec2, rec3, rec4, rec6, rec5, rec7, rec8, rec9, rec10];
  let array2 = [
    rec11,
    rec12,
    rec13,
    rec14,
    rec15,
    rec16,
    rec17,
    rec18,
    rec19,
    rec20
  ];

  return (
    <Marquee className="parent" direction={direction}>
      {(direction === "right" ? array2 : array).map((itm, ind) => (
        <div key={ind} className="imageWrapper">
          <img src={itm} alt={itm} />
        </div>
      ))}
    </Marquee>
  );
};

export default Myslider;
