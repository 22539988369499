import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { cocktailDetails } from "constants/cocktail";
import { BASE_IMAGE_URL } from "utils/constants";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import useScreenMobile from "hooks/useScreenMobile";

const RecipeSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  const breakpointsForLength = {
    360: 1,
    375: 1,
    414: 1,
    768: 2,
    820: 3,
    1024: 2,
    1200: 2.8,
    1440: 3
  };

  const calculatedLength = breakpointsForLength[window.innerWidth] || 3;

  const NextArrow = `${BASE_IMAGE_URL}/home/nextIcon.svg`;
  const PrevArrow = `${BASE_IMAGE_URL}/home/prevIcon.svg`;

  const isTab = useScreenMobile({ size: 768 });
  const isMobile = useScreenMobile({ size: 568 });

  return (
    <>
      <Swiper
        modules={[Navigation]}
        slidesPerView={2.5}
        spaceBetween={50}
        navigation={{
          nextEl: ".imageButtonNext",
          prevEl: ".imageButtonPrev"
        }}
        breakpoints={{
          360: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          375: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          414: {
            slidesPerView: 1,
            spaceBetween: 50
          },
          540: {
            slidesPerView: 1.5,
            spaceBetween: 50
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          820: {
            slidesPerView: 2.8,
            spaceBetween: 30
          },
          912: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1024: {
            slidesPerView: 2.25,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 2.8,
            spaceBetween: 30
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 40
          }
        }}
        onSlideChange={handleSlideChange}
      >
        {cocktailDetails.map((item, index) => (
          <SwiperSlide key={index}>
            <Link to={item.link}>
              <div className="recipeSwipe">
                <div className="recipeSwiper">
                  <div className="recipeSwiperProfile">
                    <img src={item?.icon} alt="logo" />
                    <div className="recipeSwiperProfileData">
                      <p>{item?.madeWith}</p>
                      <span>{item?.authorName}</span>
                    </div>
                  </div>

                  <div className="imageContainer">
                    <video
                      className="recipeVideo"
                      loop
                      muted
                      autoPlay
                      playsInline
                      preload="autoPlay"
                    >
                      <source src={item?.video} />
                    </video>
                  </div>
                </div>

                <div className="recipeName">
                  <label>{item?.brandName}</label>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>

      <span
        className={
          currentIndex === 0 ? "imageButtonPrev hide" : "imageButtonPrev"
        }
      >
        <img src={PrevArrow} alt="PrevArrow" />
      </span>

      <span
        className={
          currentIndex ===
          cocktailDetails.length - (!isTab || !isMobile ? calculatedLength : 1)
            ? "imageButtonNext hide"
            : "imageButtonNext"
        }
      >
        <img src={NextArrow} alt="NextArrow" />
      </span>
    </>
  );
};

export default RecipeSlider;
