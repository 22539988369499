import React, { useState, useEffect } from "react";
import videoIntro from "./videoIntro.gif";

import "./videoIntro.scss";

const VideoIntro = ({ onVideoEnd }) => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isVideoEnded, setIsVideoEnded] = useState(false);

  useEffect(() => {
    const increaseProgress = () => {
      const interval = setInterval(() => {
        setLoadingProgress((prevProgress) => {
          const newProgress = Math.min(prevProgress + 1, 100);
          if (newProgress >= 100) {
            clearInterval(interval);
            setIsVideoEnded(true);
            setTimeout(onVideoEnd, 500);
          }
          return newProgress;
        });
      }, 25);
    };

    increaseProgress();

    return () => {
      setLoadingProgress(0);
    };
  }, [onVideoEnd]);

  return (
    <div className={`videoIntro ${isVideoEnded ? "end" : ""}`}>
      <img id="introVideo" width="100%" src={videoIntro} alt="Video Intro" />
      <div className="loadingProgress">
        <p>{loadingProgress}%</p>
      </div>
    </div>
  );
};

export default VideoIntro;
