const Ingredients = ({ cocktail, title }) => {
  const showIngredients = Object.keys(cocktail).length > 0;
  if (!showIngredients) {
    return null;
  }

  return (
    <section className="ingredient">
      <div className="container ingredientContainer">
        <div className="topBox">
          <div className="topBoxLeft">
            <h1>Ingredients</h1>
            {cocktail.ingredients.map((obj, ind) => (
              <div key={ind} className="valueNameWrapper">
                <span className="value">{obj.value}</span>
                <p>{obj.name}</p>
              </div>
            ))}
          </div>
          <div className="topBoxRight">
            <h1>{title}</h1>
            {cocktail.preparationList.map((obj, ind) => (
              <div key={ind} className="valueNameWrapper">
                <span className="value">{obj.value}</span>
                <p>{obj.name}</p>
              </div>
            ))}
          </div>
        </div>
        {cocktail.tipsList && (
          <div className="bottomBox">
            <h1>Tips</h1>
            {cocktail.tipsList.map((tip, inx) => (
              <div key={inx} className="liWrapper">
                <li>{tip}</li>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Ingredients;
