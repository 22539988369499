// import bgFirst from "assets/images/experience/bgFirst.svg";
// import bgMiddle from "assets/images/experience/bgMiddle.svg";
// import bgLast from "assets/images/experience/bgLast.svg";

import { BASE_IMAGE_URL } from "utils/constants";

export const experience = [
  {
    id: 1,
    title: "Unmatched Flavor Profile",
    backgroundImage: `${BASE_IMAGE_URL}/experience/bgFirst.svg`,
    description:
      "Exquisite line of spirits infused with subcontinent’s most beloved ingredients- Jamun, Paani Puri, Bael."
  },
  {
    id: 2,
    title: "Cultural Odyssey for the Palate",
    backgroundImage: `${BASE_IMAGE_URL}/experience/bgMiddle.svg`,
    description:
      "Drawing from the rich tapestry of India’s culinary heritage, crafting an alchemy of tastes that transcends the ordinary."
  },
  {
    id: 3,
    title: "Crafted with Passion",
    backgroundImage: `${BASE_IMAGE_URL}/experience/bgLast.svg`,
    description:
      "Handcrafted with passion, inspired by traditional recipes, explore a world of untapped sensations and experiences."
  }
];
