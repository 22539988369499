import { Link } from "react-router-dom";

import Button from "components/Button";
import FadeUp from "components/FadeUp";

import useScreenMobile from "hooks/useScreenMobile";

import videoPoster from "./BannerImage.webp";

import "components/Banner/banner.scss";

const Banner = ({
  desktopBannerImage,
  tabBannerImage,
  mobileBannerImage,
  heading,
  paragraph,
  button,
  bannerHeading,
  desktopContainer,
  bannerTabContainer,
  desktopBannerVideo,
  linkName
}) => {
  const isTab = useScreenMobile({ size: 992 });
  const isMobile = useScreenMobile({ size: 760 });

  const desktopStyle = {
    backgroundImage: `url(${desktopBannerImage})`
  };

  const tabStyle = {
    backgroundImage: `url(${tabBannerImage})`
  };

  const mobileStyle = {
    backgroundImage: `url(${mobileBannerImage})`
  };

  return (
    <>
      {desktopBannerVideo ? (
        <>
          <div className="videoContainer">
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              className="videoBackground"
              poster={videoPoster}
            >
              <source src={desktopBannerVideo} type="video/mp4" />
              Your browser does not support the video.
            </video>

            <div className={`bannerHeading ${bannerHeading}`}>
              <h2>{heading}</h2>
              <p>{paragraph}</p>
            </div>
          </div>
        </>
      ) : (
        <section
          className={`bannerDesktop  ${desktopContainer}  `}
          style={isMobile ? mobileStyle : isTab ? tabStyle : desktopStyle}
        >
          <div
            className={`container  bannerDesktopContainer ${desktopContainer} ${bannerTabContainer} `}
          >
            <FadeUp>
              <div
                className={
                  isMobile
                    ? "mobileBanner"
                    : isTab
                      ? "tabBanner"
                      : "bannerDescription"
                }
              >
                <div className={`bannerHeading ${bannerHeading}`}>
                  <h2>{heading}</h2>
                  <p>{paragraph}</p>
                </div>
                <Link to={`/${linkName}`}>
                  <div>{button && <Button>{button}</Button>}</div>
                </Link>
              </div>
            </FadeUp>
          </div>
        </section>
      )}
    </>
  );
};

export default Banner;
