import { ourStoryPlaces } from "constants/ourStoryPlaces";

import FadeUp from "components/FadeUp";

import "containers/OurStory/ourStory.scss";

const OurStoryPlaces = () => {
  return (
    <section className="ourStoryPlaces">
      <div className="container ourStoryPlacesContainer">
        {ourStoryPlaces.map((item, index) => (
          <div className="placesContainer" key={index}>
            <div className="placesImage">
              <img src={item.image} alt="" />
            </div>
            <FadeUp>
              <div className="commonHeading placesContent">
                <h2>{item.heading}</h2>
                <p>{item.description}</p>
              </div>
            </FadeUp>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurStoryPlaces;
