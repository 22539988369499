export const privacyPolicy = [
  {
    name: "Privacy Policy",
    date: "Effective Date: May 30, 2024",
    para: "Welcome to Tharra Company LLC. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website https://tharra.co/. Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the site."
  },
  {
    title: "Information We Collect",
    description: [
      "Personal Data: We may collect personally identifiable information such as your name, email address, and any other information you voluntarily provide when you contact us.",
      "Usage Data: We may collect information automatically when you visit our website, such as your IP address, browser type, access times, and the pages you view."
    ]
  },
  {
    title: "How We Use Your Information",
    subHeading:
      "We use the information we collect for various purposes, including:",
    description: [
      "To respond to your questions and feedback.",
      "To improve our website and services.",
      "To communicate with you about updates, promotions, and events."
    ]
  },
  {
    title: "How We Use Your Information",
    subHeading:
      "We use the information we collect for various purposes, including:",
    description: [
      "To respond to your questions and feedback.",
      "To improve our website and services.",
      "To communicate with you about updates, promotions, and events."
    ]
  },
  {
    title: "Disclosure of Your Information",
    subHeading: "We may share your information in the following situations:",
    description: [
      "With Service Providers: We may share your information with third-party vendors who perform services on our behalf.",
      "For Legal Reasons: We may disclose your information if required by law or in response to valid requests by public authorities."
    ]
  },
  {
    title: "Security of Your Information",
    describe:
      "We use administrative, technical, and physical security measures to protect your personal information. However, no security measures are foolproof, and we cannot guarantee the security of your information."
  },
  {
    title: "Your Data Protection Rights",
    subHeading: "You have the right to:",
    description: [
      "Access the personal data we hold about you.",
      "Request correction or deletion of your personal data.",
      "Object to or restrict the processing of your personal data.",
      "To exercise these rights, please contact us at contact@tharra.co."
    ]
  },
  {
    title: "Cookies and Tracking Technologies",
    describe:
      "We use cookies to enhance your experience on our website. You can control the use of cookies at the individual browser level. If you choose to disable cookies, it may limit your use of certain features or functions on our website."
  },
  {
    title: "Changes to This Privacy Policy",
    describe:
      "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. Changes are effective immediately upon posting."
  },
  {
    title: "Contact Us",
    describe:
      "If you have any questions about this Privacy Policy, please contact us at contact@tharra.co."
  }
];
