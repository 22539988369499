// import mountain from "assets/images/ourStory/mountain.webp";
// import house from "assets/images/ourStory/house.webp";
// import food from "assets/images/ourStory/food.webp";

import { BASE_IMAGE_URL } from "utils/constants";

export const ourStoryPlaces = [
  {
    id: 1,
    image: `${BASE_IMAGE_URL}/ourStory/mountain.webp`,
    heading: "Clean & Crisp",
    description:
      "Crafted with water sourced from Idaho's pristine springs and aquifers, our vodka embodies quality and sustainability."
  },
  {
    id: 2,
    image: `${BASE_IMAGE_URL}/ourStory/house.webp`,
    heading: "Crafted in Bellevue, WA",
    description: (
      <>
        Our flavors are handcrafted in small batches in the urban oasis of
        Bellevue
        <br />
        Washington
      </>
    )
  },
  {
    id: 3,
    image: `${BASE_IMAGE_URL}/ourStory/food.webp`,
    heading: " Authentic Indian Flavors",
    description:
      "Our flavors are a symphony of ethically sourced Indian fruits & spices, and fresh-cut herbs from the Pacific Northwest. "
  }
];
