import { Link } from "react-router-dom";
import { experience } from "constants/experience";

import Button from "components/Button";
import FadeUp from "components/FadeUp";

import useScreenMobile from "hooks/useScreenMobile";

// import cardFirst from "assets/images/experience/cardFirst.svg";
// import second from "assets/images/experience/second.svg";
// import secondCard from "assets/images/experience/cardSecond.svg";

import "containers/Home/home.scss";
import { BASE_IMAGE_URL } from "utils/constants";

const Experience = () => {
  const flower = `${BASE_IMAGE_URL}/experience/flower.svg`;

  const isTab = useScreenMobile({ size: 992 });
  const isMobile = useScreenMobile({ size: 760 });

  return (
    <section className=" experience ">
      <div
        className={
          isTab
            ? " experienceContainer   tabExperience"
            : "container experienceContainer"
        }
      >
        <FadeUp>
          <div className="commonHeading experienceHeading">
            <h2 className={isMobile ? "mobileExperience" : ""}>
              Taste of Happiness:
              {(!isTab || isTab) && <br />} Authentic Indian Flavors,
              {isMobile && <br />} Crafted
              {isTab && !isMobile && <br />} with Love
            </h2>
            <p>
              Experience the rich heritage of India in every sip, inspired by
              traditional recipes; a symphony of unmatched flavors that stands
              alone in the market.
            </p>
          </div>
        </FadeUp>
        <div className={`experienceCards ${isTab ? "tabExperience" : ""}`}>
          {experience.map((item, index) => (
            <div
              className="experienceCard"
              key={index}
              style={{
                backgroundImage: `url(${item.backgroundImage})`,
                backgroundPosition: "100%",
                backgroundSize: "cover"
              }}
            >
              {/* {item.id === 1 ? (
                <img
                  className="cardSecondBackgroundImage"
                  src={cardFirst}
                  alt="backgroundDesign"
                />
              ) : (
                <img
                  className="cardBackgroundImage"
                  src={cardFirst}
                  alt="backgroundDesign"
                />
              )} */}
              <FadeUp>
                <div className="experienceCardDeatails">
                  <div>
                    <img src={flower} alt="flower" />
                  </div>
                  <div className="cardDetails">
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              </FadeUp>
            </div>
          ))}
        </div>
        <FadeUp>
          <Link to={"/our-story"}>
            <Button>Our Story</Button>
          </Link>
        </FadeUp>
      </div>
    </section>
  );
};

export default Experience;
