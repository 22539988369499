export const termsAndConditions = [
  {
    name: "Terms and Conditions",
    date: "Effective Date: May 30, 2024",
    para: "Welcome to Tharra Company LLc. These Terms and Conditions govern your use of our website https://tharra.co/. By accessing or using our website, you agree to be bound by these Terms and Conditions."
  },

  {
    title: "Acceptance of Terms",
    describe:
      "By accessing or using our website, you agree to comply with and be bound by these Terms and Conditions and our Privacy Policy. If you do not agree with these terms, please do not use our website."
  },
  {
    title: "Use of the Website",
    describe:
      "You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our website."
  },
  {
    title: "Intellectual Property",
    describe:
      "All content on this website, including but not limited to text, graphics, logos, and images, is our property or the property of our licensors and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without our express written permission."
  },
  {
    title: "User Content",
    describe:
      "You may have the opportunity to post content on our website, such as comments or feedback. By posting content, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, and display your content in connection with our website."
  },
  {
    title: "Disclaimers and Limitation of Liability",
    describe:
      "The website is provided on an as is and as available basis. We make no warranties, express or implied, about the operation of the website or the information, content, or materials included on the website. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. We will not be liable for any damages of any kind arising from the use of the website, including but not limited to direct, indirect, incidental, punitive, and consequential damages."
  },
  {
    title: "Governing Law",
    describe:
      "These Terms and Conditions are governed by and construed in accordance with the laws of state of Washington, USA, without regard to its conflict of law principles. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Washington, USA."
  },
  {
    title: "Changes to Terms and Conditions",
    describe:
      "We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Your continued use of the website following any changes constitutes your acceptance of the new terms."
  },
  {
    title: "Contact Us",
    describe:
      "If you have any questions about these Terms and Conditions, please contact us at contact@tharra.co."
  }
];
