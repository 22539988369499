import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ourProducts } from "constants/ourProduct";

import OurProduct from "components/OurProduct";
import ProductDetail from "components/ProductDetail";
import NearBy from "components/NearBy";

import useScreenMobile from "hooks/useScreenMobile";

import "containers/ProductCard/productCard.scss";

const ProductCard = () => {
  const isMobile = useScreenMobile({ size: 760 });

  const navigate = useNavigate();

  const handleProductId = (id) => {
    navigate(`/our-spirits/${id}`);
  };

  const { id } = useParams();

  const productFilter = ourProducts.filter((item) => item.linkName !== id);

  return (
    <>
      <ProductDetail />
      <div className={"ourProduct container"}>
        <div className="commonHeading">
          <h2>You May also like</h2>
          {isMobile ? (
            <p>
              Discover More Products That Align With Your Taste and Preferences.
              Enjoy the Adventure
            </p>
          ) : (
            <p>
              Discover more products that align with your taste and preferences.
            </p>
          )}
        </div>
        <ul className="mapData">
          {productFilter.slice(0, 3).map((item, index) => (
            <OurProduct
              item={item}
              key={index}
              handleProductId={handleProductId}
            />
          ))}
        </ul>
      </div>
      <NearBy productDetailsNearBy="productDetailsNearBy" />
    </>
  );
};

export default ProductCard;
