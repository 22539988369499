import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollOnTop from "components/ScrollOnTop";
import Cookie from "components/CookiePopUp/index";
import CookieModal from "components/CookieModal/index";
import VideoIntro from "components/VideoIntro";
import NotFound from "components/NotFound/index";
import AppLayouts from "layouts/AppLayouts";

import Home from "containers/Home";
import OurCocktails from "containers/OurCocktails";
import OurSpirits from "containers/OurSpirits";
import ProductCard from "containers/ProductCard";
import CocktailDetails from "containers/CocktailDetails";
import OurStory from "containers/OurStory";
import ContactUs from "containers/ContactUs";
import VisitUs from "containers/VisitUs";
import PrivacyPolicy from "containers/PrivacyPolicy";
import TermsAndConditions from "containers/TermsAndConditions";

import useScreenMobile from "hooks/useScreenMobile";

import "styles/common.scss";

const App = () => {
  const [showCookieModal, setShowCookieModal] = useState(false);
  const isTab = useScreenMobile({ size: 992 });

  const [showCookie, setShowCookie] = useState(
    () => localStorage.getItem("showCookie") === "true"
  );

  const [showImage, setShowImage] = useState(false);
  const [showWhiteImage, setShowWhiteImage] = useState(false);

  useEffect(() => {
    const handleStorageChange = () => {
      setShowCookie(localStorage.getItem("showCookie") === "true");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const [cookies, setCookies] = useState({
    necessary: true,
    functional: false,
    performance: false
  });

  const [showApp, setShowApp] = useState(true);

  useEffect(() => {
    const videoPlayed = sessionStorage.getItem("videoPlayed");

    if (videoPlayed) {
      setShowApp(false);
    }

    if (showCookieModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [showCookieModal]);

  const handleVideoEnd = () => {
    setShowApp(false);
    sessionStorage.setItem("videoPlayed", "true");
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage = (scrollTop / docHeight) * 100;

    if (scrollPercentage > 88) {
      setShowWhiteImage(true);
    } else if (scrollPercentage > 10) {
      setShowImage(true);
      setShowWhiteImage(false);
    } else {
      setShowImage(false);
      setShowWhiteImage(false);
    }
  };

  const handleImageClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      {showApp && <VideoIntro onVideoEnd={handleVideoEnd} />}

      {!showApp && (
        <BrowserRouter>
          <ScrollOnTop />

          {isTab && showImage && (
            <div
              onClick={handleImageClick}
              style={{
                position: "fixed",
                bottom: 18,
                right: 18,
                zIndex: "99",
                cursor: "pointer"
              }}
            >
              <img
                src={
                  showWhiteImage
                    ? "https://tharra-website-assets.s3.amazonaws.com/assets/images/banner/topIconWhite.svg"
                    : "https://tharra-website-assets.s3.amazonaws.com/assets/images/banner/topIconBlack.svg"
                }
                alt="topIcon"
              />
            </div>
          )}

          <Routes>
            <Route
              path="/"
              element={
                <AppLayouts>
                  <Home setShowCookie={setShowCookie} />
                </AppLayouts>
              }
            />
            <Route
              path="/our-cocktail"
              element={
                <AppLayouts>
                  <OurCocktails />
                </AppLayouts>
              }
            />
            <Route
              path="/our-spirits"
              element={
                <AppLayouts>
                  <OurSpirits />
                </AppLayouts>
              }
            />
            <Route
              path="/cocktail-details/:id"
              element={
                <AppLayouts>
                  <CocktailDetails />
                </AppLayouts>
              }
            />
            <Route
              path="/our-spirits/:id"
              element={
                <AppLayouts>
                  <ProductCard />
                </AppLayouts>
              }
            />
            <Route
              path="/our-story"
              element={
                <AppLayouts>
                  <OurStory />
                </AppLayouts>
              }
            />
            <Route
              path="/contact-us"
              element={
                <AppLayouts>
                  <ContactUs />
                </AppLayouts>
              }
            />
            <Route
              path="/visit-us"
              element={
                <AppLayouts>
                  <VisitUs />
                </AppLayouts>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <AppLayouts>
                  <PrivacyPolicy />
                </AppLayouts>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <AppLayouts>
                  <TermsAndConditions />
                </AppLayouts>
              }
            />
            <Route
              path="*"
              element={
                <AppLayouts headerRequired={false} footerRequired={false}>
                  <NotFound />
                </AppLayouts>
              }
            />
          </Routes>

          {showCookieModal && (
            <CookieModal
              cookies={cookies}
              setCookies={setCookies}
              setShowCookie={setShowCookie}
              setShowCookieModal={setShowCookieModal}
            />
          )}

          <Cookie
            cookies={cookies}
            setCookies={setCookies}
            showCookie={showCookie}
            setShowCookie={setShowCookie}
            setShowCookieModal={setShowCookieModal}
          />
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;
