export const readyToDrink = [
  {
    id: 1,
    name: "Paani Madiraah",
    details:
      "Experience vibrant flavors with Paani Madiraah: a bold blend of spices and refreshing zest. "
  },
  {
    id: 2,
    name: "Jamun Madiraah",
    details:
      "Savor the taste of Jamun Madiraah: sweet, tart, and balanced with a spicy kick."
  },
  {
    id: 3,
    name: "Bael Madiraah",
    details:
      "Indulge in Bael Madiraah: a unique infusion of tangy, sweet, and notes in smooth vodka."
  }
];
