import React from "react";

import "components/ToggleButton/toggleButton.scss";

const ToggleButton = ({ value, setCookies, keyProp }) => {
  return (
    <div>
      <label className="switch">
        <input
          type="checkbox"
          value={value}
          onChange={(e) =>
            setCookies((prev) => ({ ...prev, [keyProp]: e.target.checked }))
          }
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToggleButton;
