import { Link } from "react-router-dom";
import { smoothNavigation } from "utils/scrollBehaviour";
import { BASE_IMAGE_URL } from "utils/constants";

import Button from "components/Button";
import FadeUp from "components/FadeUp";

import "containers/OurStory/ourStory.scss";

const Discover = () => {
  const DrinkingCouples = `${BASE_IMAGE_URL}/ourStory/drinkingCouples.webp`;

  return (
    <section className="discover">
      <div className="container discoverContainer">
        <div className="discoverDetails">
          <FadeUp>
            <h2>Discover India in Every Sip!</h2>
            <p>
              Every bottle tells the story of our commitment to quality and the
              celebration of Indian culture.
            </p>
          </FadeUp>

          <div
            className="discoveryButton"
            onClick={() => {
              setTimeout(() => {
                smoothNavigation("ourProduct", { ourProduct: 59 });
              }, 300);
            }}
          >
            <Link to={"/our-spirits/#ourProduct"}>
              <Button className="btnWhite">Explore More</Button>
            </Link>
          </div>
        </div>
        <div className="discoverImg">
          <img src={DrinkingCouples} alt="DrinkingCouples" />
        </div>
      </div>
    </section>
  );
};

export default Discover;
