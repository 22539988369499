// import video1 from "assets/video/tharraBaelPaaniPunch.mp4";
// import video2 from "assets/video/tharraPaaniPunch.mp4";
// import video3 from "assets/video/tharraPaaniKaGhol.mp4";
// import video4 from "assets/video/tharraJamunFizzz.mp4";

// import tharraBaelIcon from "assets/images/cocktailCard/tharraBaelIcon.webp";
// import tharraJamunIcon from "assets/images/cocktailCard/tharraJamunIcon.webp";
// import tharraPaaniIcon from "assets/images/cocktailCard/tharraPaaniIcon.webp";

import { BASE_IMAGE_URL, BASE_VIDEO_URL } from "utils/constants";

export const cocktailDetails = [
  {
    madeWith: "Made With",
    authorName: "Tharra Bael Vodka",
    brandName: "Tharra Bael  Punch",
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraBaelIcon.webp`,
    video: `${BASE_VIDEO_URL}/tharraBaelPaaniPunch.mp4`,
    link: "cocktail-details/tharra-bael-paani-punch"
  },
  {
    madeWith: "Made With",
    authorName: "Tharra Paani Vodka",
    brandName: "Tharra Paani Punch",
    video: `${BASE_VIDEO_URL}/tharraPaaniPunch.mp4`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPaaniIcon.webp`,
    link: "cocktail-details/tharra-paani-punch"
  },
  {
    madeWith: "Made With",
    authorName: "Tharra Paani Vodka",
    brandName: "Tharra Paani Surprise",
    video: `${BASE_VIDEO_URL}/tharraPaaniKaGhol.mp4`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPaaniIcon.webp`,
    link: "cocktail-details/tharra-paani-surprise"
  },
  {
    madeWith: "Made With",
    authorName: "Tharra Jamun Vodka",
    brandName: "Tharra Jamun Fizzz",
    video: `${BASE_VIDEO_URL}/tharraJamunFizzz.mp4`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraJamunIcon.webp`,
    link: "cocktail-details/tharra-jamun-fizzz"
  }
];
