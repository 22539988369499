import Button from "components/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import "components/OurProduct/ourProduct.scss";

const OurProduct = ({ handleProductId, item }) => {
  return (
    <li className="ourProductList">
      <div className="ourProductListImg">
        {/* <img src={item?.image} alt="jamunBottle" /> */}
        <LazyLoadImage
          src={item?.image}
          effect="blur"
          placeholderSrc={item?.image}
        ></LazyLoadImage>
      </div>
      <div className="ourProductListData">
        <div className="ourProductListDataOwner">
          <div className="ourProductListDataOwnerName">
            <h3>{item?.title}</h3>
            <span>{item?.percentage}</span>
          </div>
          <span className="ourProductListDataOwnerCount">{item?.amount}</span>
        </div>

        <p className="ourProductListDataPara">{item?.description}</p>

        <Button
          className="ourProductListDataButton"
          arrowBtn="removeArrow"
          onClick={() => {
            handleProductId(item.linkName);
            window.scrollTo(0, 0);
          }}
        >
          {item?.btnText}
        </Button>
      </div>
    </li>
  );
};

export default OurProduct;
