import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { cocktailListData } from "constants/cocktailList";

import { smoothNavigation } from "utils/scrollBehaviour";

import Button from "components/Button";
import CocktailCard from "components/CocktailCard";

import "containers/ProductCard/productCard.scss";

const Explore = () => {
  const { id } = useParams();

  return (
    <section className="explore">
      <div className="container">
        <div className="commonHeading exploreTitle">
          <h2>You May also like</h2>
          <p>Explore More Recipes.</p>
        </div>
        <div className="exploreCocktails">
          {cocktailListData
            .filter((itm) => itm.linkName !== id)
            .sort(() => Math.random() - 0.5)
            .slice(0, 4)
            .map((data) => (
              <CocktailCard key={data.id} data={data} />
            ))}
        </div>

        <div
          className="exploreBtn"
          onClick={() => {
            setTimeout(() => {
              smoothNavigation("cocktailsList", { cocktailsList: 59 });
            }, 300);
          }}
        >
          <Link to={"/our-cocktail/#cocktailsList"} className="link">
            <Button className="btnWhite">Browse all Recipes</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Explore;
