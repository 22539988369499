import { useState } from "react";
import { Link } from "react-router-dom";

import useScreenMobile from "hooks/useScreenMobile";

import FadeUp from "components/FadeUp";
import Button from "components/Button";

import RecipeSlider from "containers/Home/RecipeSlider";
import { smoothNavigation } from "utils/scrollBehaviour";

const CocktailRecipe = () => {
  const [showArrow, setShowArrow] = useState(false);
  const isMobile = useScreenMobile({ size: 992 });

  return (
    <section className="recipe">
      <FadeUp>
        <div className="container recipeContainer">
          <div className="recipeContent">
            <h2>Tharra Cocktail Recipes</h2>
            <p>
              Discover a variety of expertly crafted cocktails in our
              collection.
            </p>
            {!isMobile && (
              <Link to={"/our-cocktail/#cocktailsList"}>
                <div
                  onClick={() => {
                    setTimeout(() => {
                      smoothNavigation("cocktailsList", { cocktailsList: 59 });
                    }, 300);
                  }}
                >
                  <Button>See More</Button>
                </div>
              </Link>
            )}
          </div>

          <RecipeSlider setShowArrow={setShowArrow} showArrow={showArrow} />

          {isMobile && (
            <div className="recipeButton">
              <Link to={"/our-cocktail/#cocktailsList"}>
                <div
                  onClick={() => {
                    setTimeout(() => {
                      smoothNavigation("cocktailsList", { cocktailsList: 59 });
                    }, 300);
                  }}
                >
                  <Button>See More</Button>
                </div>
              </Link>
            </div>
          )}
        </div>
      </FadeUp>
    </section>
  );
};

export default CocktailRecipe;
