import { useNavigate } from "react-router-dom";

import Button from "components/Button";

import "components/CocktailCard/cocktailCard.scss";

const CocktailCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="cocktailCard">
      <div className="cocktailCardContent">
        <div className="cocktailCardContentImg">
          <img src={data.img} alt="CocktailImg" />
        </div>
        <div className="cocktailCardContentMain">
          <div className="cocktailCardContentMainLogo">
            <img src={data.icon} alt="CircleBottle" />
          </div>
          <div className="cocktailCardContentMainDetails">
            <p>Made with</p>
            <h5>{data.madeWith}</h5>
          </div>
        </div>

        <div className="cocktailCardContentHide">
          <div className="cocktailCardContentHidePoints">
            <h5>Ingredients</h5>
            <div className="blurBox">
              <ul>
                {data.ingredients.map((list, id) => (
                  <div key={id} className="valueBox">
                    <li
                      style={{ visibility: list.value ? "visible" : "hidden" }}
                    >
                      {list.value}
                    </li>
                    <span>{list.name}</span>
                  </div>
                ))}
              </ul>
            </div>
          </div>
          <Button
            onClick={() => navigate(`/cocktail-details/${data.linkName}`)}
            className="btnWhite"
          >
            Get the Recipe
          </Button>
        </div>

        {data.tagImg && (
          <div className="cocktailTagContainer">
            <div className="cocktailTagContent">
              <img src={data.tagImg} alt="" />
              <p>{data.tagName}</p>
            </div>
          </div>
        )}
      </div>

      <h4>{data.name}</h4>
    </div>
  );
};

export default CocktailCard;
