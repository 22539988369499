// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { ourSpiritSlide } from "constants/ourSpirits";
import { BASE_IMAGE_URL } from "utils/constants";
import { Link } from "react-router-dom";

import Button from "components/Button";
import FadeUp from "components/FadeUp";

// import NextArrow from "assets/images/home/buttonArrow.svg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "containers/Home/home.scss";
import useScreenMobile from "hooks/useScreenMobile";

const OurSpirits = () => {
  let isMobile = useScreenMobile({ size: 568 });
  const NextArrow = `${BASE_IMAGE_URL}/home/nextArrow.svg`;
  const PrevArrow = `${BASE_IMAGE_URL}/home/prevArrow.svg`;

  return (
    <section className="ourSpirits">
      <div className="container  ourSpiritsContainer">
        <FadeUp>
          <div className="commonHeading ourSpiritsHeading">
            <h2>
              Discover our Exquisite {isMobile && <br />}Spirit collection
            </h2>
            <p>
              Experience Pure Bliss with Our Meticulously Crafted Premium
              Vodkas.
            </p>
          </div>
        </FadeUp>

        <FadeUp>
          <div className="ourSpiritsSlider">
            <Swiper
              modules={[Navigation]}
              centeredSlides={true}
              speed={500}
              loop={true}
              navigation={{
                nextEl: ".ourSpiritsSliderArrowsNext",
                prevEl: ".ourSpiritsSliderArrowsPrev"
              }}
              breakpoints={{
                1440: {
                  slidesPerView: 3
                },
                1200: {
                  slidesPerView: 3
                },
                1000: {
                  slidesPerView: 3
                },
                992: {
                  slidesPerView: 1,
                  centeredSlides: true
                }
              }}
            >
              {ourSpiritSlide.map((data) => (
                <SwiperSlide key={data.id}>
                  <div className="ourSpiritsSliderBox">
                    <div className="ourSpiritsSliderBoxImg">
                      <img src={data.bottleImg} alt="Bottle" />
                    </div>
                    <div className="ourSpiritsSliderBoxDetails">
                      <div className="ourSpiritsSliderBoxDetailsImg">
                        <img src={data.fruitImg} alt="Bael" />
                      </div>
                      <div className="ourSpiritsSliderBoxDetailsPrice">
                        <div className="priceSection">
                          <div className="priceSectionDetails">
                            <h4>{data.name}</h4>
                            <p>{data.quantity}</p>
                          </div>
                          <div className="priceSectionTag">
                            <p>{data.price}</p> <span>{data.introductory}</span>
                          </div>
                        </div>
                        <div className="buyOption">
                          {/* <div className="blurText"> */}
                          <p>{data.details}</p>
                          {/* </div> */}
                          <Link to={`/our-spirits/${data.link}`}>
                            <Button className="btnWhite">Know More</Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="ourSpiritsSliderArrows">
              <span className="ourSpiritsSliderArrowsPrev">
                <img src={PrevArrow} alt="PrevArrow" />
              </span>
              <span className="ourSpiritsSliderArrowsNext">
                <img src={NextArrow} alt="NextArrow" />
              </span>
            </div>
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default OurSpirits;
