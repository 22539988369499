import { Link } from "react-router-dom";
import { BASE_IMAGE_URL } from "utils/constants";

import ToggleButton from "components/ToggleButton/index";

// import toggle from "assets/images/cookieModal/toggle.svg";
// import close from "assets/images/cookieModal/cookieModalClose.svg";

import "components/CookieModal/cookieModal.scss";

const CookieModal = ({
  setShowCookieModal,
  setShowCookie,
  cookies,
  setCookies
}) => {
  const handleCookies = () => {
    setShowCookie(false);
    localStorage.setItem("acceptSelectedCookies", JSON.stringify(cookies));
    setShowCookieModal(false);
    localStorage.removeItem("showCookie");
  };

  return (
    <div className="cookieModalBox">
      <div className="modal">
        <div className="modalTop">
          <h1>Manage Cookies</h1>
          <p>
            Welcome to Tharra Company LLC. This Privacy Policy explains how we
            collect, use, disclose, and safeguard your information when you
            visit our website https://tharra.co/. Please read this Privacy
            Policy carefully. If you do not agree with the terms of this Privacy
            Policy, please do not access the site.
          </p>
          <Link to="/privacy-policy">
            <span
              onClick={() => setShowCookieModal(false)}
              className="readMore"
            >
              read more
            </span>
          </Link>
        </div>
        <div className="modalMiddle">
          <div className="toggleBox">
            <p>Necessary Cookies</p>
            <img src={`${BASE_IMAGE_URL}/cookieModal/toggle.svg`} alt="" />
          </div>
          <div className="toggleBox">
            <p>Functional Cookies</p>
            <ToggleButton
              setCookies={setCookies}
              value={cookies.functional}
              keyProp="functional"
            />
          </div>
          <div className="toggleBox">
            <p>Performance Cookies</p>
            <ToggleButton
              setCookies={setCookies}
              value={cookies.performance}
              keyProp="performance"
            />
          </div>
        </div>
        <button onClick={handleCookies}>Accept Cookies</button>
        <img
          onClick={() => setShowCookieModal(false)}
          className="close"
          src={`${BASE_IMAGE_URL}/cookieModal/cookieModalClose.svg`}
          alt=""
        />
      </div>
    </div>
  );
};

export default CookieModal;
