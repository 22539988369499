import { Link } from "react-router-dom";
import {
  footerCocktails,
  footerOurSpirits,
  footerPages
} from "constants/footer";

import FadeUp from "components/FadeUp";
import EmailUpdate from "components/EmailUpdates/EmailUpdate";
// import tharaLogo from "assets/tharaCompanyLogo.webp";

import "components/Footer/footer.scss";
import { smoothNavigation } from "utils/scrollBehaviour";
import { BASE_IMAGE_URL } from "utils/constants";

const Footer = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const footerImageDesktop = `${BASE_IMAGE_URL}/home/footerImgDesktop.webp`;
  const tharaLogo = `${BASE_IMAGE_URL}/home/tharaCompanyLogo.webp`;
  const footerImageMobile = `${BASE_IMAGE_URL}/home/footerImageMobile.webp`;
  const faceBookIcon = `${BASE_IMAGE_URL}/home/faceBookIcon.svg`;
  const instagramIcon = `${BASE_IMAGE_URL}/home/instagramIcon.svg`;
  const linkedInIcon = `${BASE_IMAGE_URL}/home/linkedInIcon.svg`;

  const mobileUrl =
    "https://www.facebook.com/share/xepuyqEHKhD1tBPP/?mibextid=qi2Omg";
  const desktopUrl =
    "https://www.facebook.com/people/Tharra-Company/61560236348671/?mibextid=ZbWKwL";

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleClick = (event) => {
    event.preventDefault();
    const url = isMobile ? mobileUrl : desktopUrl;

    if (isMobile) {
      // First, attempt to open in the Facebook app
      const fbAppUrl = "fb://facewebmodal/f?href=" + encodeURIComponent(url);
      window.location.href = fbAppUrl;

      // Fallback to the browser after a short delay
      setTimeout(() => {
        window.location.href = url;
      }, 2000);
    } else {
      window.open(url, "_blank", "noopener noreferrer");
    }
  };

  return (
    <footer className="footer">
      <div className="fcontainer footerContainer">
        <FadeUp>
          <div className="footerContentBox">
            <div className="leftBox">
              <div className="companySpiritCocktailBox">
                <div className="categoryBox">
                  <h4>Company</h4>
                  <div className="optionsBox">
                    {footerPages.map((item, index) => (
                      <Link key={index} to={item.link}>
                        <p onClick={scrollTop}>{item.pageName}</p>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="categoryBox">
                  <h4>Our Spirits</h4>
                  <div className="optionsBox">
                    {footerOurSpirits.map((item, index) => (
                      <Link key={index} to={`/our-spirits/${item.footerLink} `}>
                        <p onClick={scrollTop}>{item.footerSpiritName}</p>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="categoryBox">
                  <h4>Our Cocktails</h4>
                  <div className="optionsBox">
                    {footerCocktails.map((item, index) => (
                      <Link
                        key={index}
                        to={
                          item.footerCocktailsName === "See All"
                            ? "/our-cocktail/#cocktailsList"
                            : `/cocktail-details/${item.footerLink} `
                        }
                      >
                        <p
                          style={{
                            textDecoration:
                              item.footerCocktailsName === "See All"
                                ? "underline"
                                : "none"
                          }}
                          onClick={() => {
                            scrollTop();
                            if (item.footerCocktailsName === "See All") {
                              setTimeout(() => {
                                smoothNavigation("cocktailsList", {
                                  cocktailsList: 59
                                });
                              }, 300);
                            }
                          }}
                        >
                          {item.footerCocktailsName}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <div className="tharaCompanyBox">
                <div className="tharaLogoBox">
                  <img src={tharaLogo} alt="tharaLogo" />
                </div>
                <div className="socialMediaBox">
                  <a
                    href={isMobile ? mobileUrl : desktopUrl}
                    onClick={handleClick}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={faceBookIcon} alt="faceBookIcon" />
                  </a>

                  <Link
                    to="https://www.instagram.com/tharracompany?igsh=NXUydXljcHFtbHhk"
                    target="_blank"
                  >
                    <img src={instagramIcon} alt="instagramIcon" />
                  </Link>

                  <Link
                    to="https://www.linkedin.com/company/tharra-company/?viewAsMember=true"
                    target="_blank"
                  >
                    <img src={linkedInIcon} alt="linkedInIcon" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="rightBox">
              <EmailUpdate btn="btn" marginB="40px" />
              <div className="imgBox">
                <img
                  className="webfooterImg"
                  src={footerImageDesktop}
                  alt="imgfooter"
                />
                <img
                  className="resfooterImg"
                  src={footerImageMobile}
                  alt="imgfooter"
                />
              </div>
            </div>
          </div>
        </FadeUp>

        <FadeUp>
          <div className="footerBottomBox">
            <p>© Tharra company llc. All rights reserved.</p>
            <div className="policy">
              <span>
                <Link to="/terms-and-conditions">Terms and conditions</Link>
              </span>
              <span>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </span>
            </div>
          </div>
        </FadeUp>
      </div>
    </footer>
  );
};

export default Footer;
