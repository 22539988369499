import { useNavigate } from "react-router-dom";

import { ourSpirits } from "constants/bannerDetails";
import { ourProducts } from "constants/ourProduct";

import Banner from "components/Banner/index";
import OurProduct from "components/OurProduct";
import FadeUp from "components/FadeUp";
import NearBy from "components/NearBy/index";

import ReadyToDrink from "containers/OurSpirits/ReadyToDrink";
import useScreenMobile from "hooks/useScreenMobile";

import "containers/OurSpirits/ourSpirits.scss";

const OurSpirits = () => {
  const navigate = useNavigate();
  const isTab = useScreenMobile({ size: 992 });

  const handleProductId = (id) => {
    navigate(`/our-spirits/${id}`);
  };

  const spritPageProduct =
    (window.location.pathname === "/our-spirits" ||
      "/our-spirits/#ourProduct") &&
    isTab;
  return (
    <>
      <Banner
        desktopBannerImage={ourSpirits?.desktopBannerImage}
        tabBannerImage={ourSpirits?.tabBannerImage}
        mobileBannerImage={ourSpirits?.mobileBannerImage}
        heading={ourSpirits?.heading}
        paragraph={ourSpirits?.paragraph}
        bannerHeading="ourSpiritsHeading"
        bannerTabContainer="ourSpiritsDesktopContainer"
      />

      <div
        style={{ marginTop: spritPageProduct ? "96px" : "0" }}
        className="ourProduct container"
        id="ourProduct"
      >
        <FadeUp>
          <div className="commonHeading ">
            <h2>Our Products</h2>
            <p>
              Explore diverse offerings, from Jamun-infused to Potato Vodka.
            </p>
          </div>
        </FadeUp>

        <ul className="mapData">
          {ourProducts.map((item, index) => (
            <OurProduct
              item={item}
              key={index}
              handleProductId={handleProductId}
              className="ourProductButton"
            />
          ))}
        </ul>
      </div>

      <ReadyToDrink />

      <NearBy />
    </>
  );
};

export default OurSpirits;
