import { useEffect, useState } from "react";
import { cocktailListData } from "constants/cocktailList";
import { useNavigate, useParams } from "react-router-dom";
import { ourProducts } from "constants/ourProduct";

import OurProduct from "components/OurProduct";
import FadeUp from "components/FadeUp";
import NearBy from "components/NearBy";

import ProductInformation from "containers/CocktailDetails/ProductInformation";
import Ingredients from "containers/CocktailDetails/Ingredients";
import Explore from "containers/CocktailDetails/Explore";
import TheEssence from "containers/CocktailDetails/TheEssence";

import "containers/CocktailDetails/cocktailDetails.scss";

const CocktailDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [cocktail, setCocktail] = useState({});

  const handleProductId = (id) => {
    navigate(`/our-spirits/${id}`);
  };

  useEffect(() => {
    let cocktail = cocktailListData.find(
      (cocktail) => cocktail.linkName === id
    );
    setCocktail(cocktail);
  }, [id]);

  return (
    <>
      <ProductInformation cocktail={cocktail} setCocktail={setCocktail} />
      <Ingredients cocktail={cocktail} title={cocktail.preparationListName} />
      <TheEssence />
      <Explore />

      <div className="ourProduct container">
        <FadeUp>
          <div className="commonHeading">
            <h2>Explore More Spirits</h2>
            <p>
              Discover more products that align with your taste and preferences.
            </p>
          </div>
        </FadeUp>

        <ul className="mapData">
          {ourProducts.map((item, index) => (
            <OurProduct
              item={item}
              key={index}
              handleProductId={handleProductId}
            />
          ))}
        </ul>
      </div>

      <NearBy ourStoryNearBy="ourStoryNearBy" />
    </>
  );
};

export default CocktailDetails;
