import FadeUp from "components/FadeUp";
import Myslider from "components/Slider/Myslider";

const Community = () => {
  return (
    <section className="community">
      <div className="communityContainer">
        <FadeUp>
          <div className="commonHeading communityHeading">
            <h2>Explore Our Community</h2>
            <p>
              Discover our vibrant community through captivating photos and
              shared moments.
            </p>
          </div>
        </FadeUp>

        <FadeUp>
          <div className="autoplayBox">
            <Myslider direction="left" />
            <Myslider direction="right" />
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default Community;
