// import BaelFruit from "assets/images/home/baelFruit.webp";
// import JamunFruit from "assets/images/home/jamunFruit.webp";
// import PaaniFruit from "assets/images/home/paani.webp";
// import PotatoFruit from "assets/images/home/potato.webp";

// import JamunBottle from "assets/images/home/jamunBottle.webp";
// import BaelBottle from "assets/images/home/baelBottle.webp";
// import PaaniBottle from "assets/images/home/paaniBottle.webp";
// import PotatoBottle from "assets/images/home/potatoBottle.webp";

import { BASE_IMAGE_URL } from "utils/constants";

export const ourSpiritSlide = [
  {
    id: 1,
    name: "Tharra Bael",
    bottleImg: `${BASE_IMAGE_URL}/home/baelBottle.webp`,
    fruitImg: `${BASE_IMAGE_URL}/home/baelFruit.webp`,
    quantity: "750 ml",
    price: "$ 34.99",
    introductory: "Introductory Price",
    details:
      "Each pour celebrates Bael's complex fusion of sour, sweet, and tangy with our crisp vodka.",
    link: "tharra-bael"
  },

  {
    id: 2,
    name: "Tharra Paani",
    bottleImg: `${BASE_IMAGE_URL}/home/paaniBottle.webp`,
    fruitImg: `${BASE_IMAGE_URL}/home/paani.webp`,
    quantity: "750 ml",
    price: "$ 34.99",
    introductory: "Introductory Price",
    details:
      "Infused with tamarind, mint, date chutney, and bold spice, capturing Paani Puri's beloved street flavors.",
    link: "tharra-paani"
  },
  {
    id: 3,
    name: "Tharra Jamun",
    bottleImg: `${BASE_IMAGE_URL}/home/jamunBottle.webp`,
    fruitImg: `${BASE_IMAGE_URL}/home/jamunFruit.webp`,
    quantity: "750 ml",
    price: "$ 34.99",
    introductory: "Introductory Price",
    details:
      "Jamun's sweet-tart taste, enriched with spices, melds into our vodka for a unique, silky libation.",
    link: "tharra-jamun"
  },
  {
    id: 4,
    name: "Potato Vodka",
    bottleImg: `${BASE_IMAGE_URL}/home/potatoBottle.webp`,
    fruitImg: `${BASE_IMAGE_URL}/home/potato.webp`,
    quantity: "750 ml",
    price: "$ 21.99",
    introductory: "Introductory Price",
    details:
      "Crafted from Idaho russet potatoes, our vodka is rich, smooth, and subtly sweet, showcasing distillation artistry.",
    link: "potato-vodka"
  }
];
