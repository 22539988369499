import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASE_IMAGE_URL } from "utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import useScreenMobile from "hooks/useScreenMobile";

import { ourProducts } from "constants/ourProduct";

import "components/ProductDetail/productDetail.scss";

const ProductDetail = () => {
  const flower = `${BASE_IMAGE_URL}/productDetails/flower.svg`;
  const flag = `${BASE_IMAGE_URL}/productDetails/flag.svg`;
  const glass = `${BASE_IMAGE_URL}/productDetails/glass.svg`;

  const isTab = useScreenMobile({ size: 992 });

  const { id } = useParams();

  const product = ourProducts.find((item) => item.linkName === id);

  const [dynamicImage, setDynamicImage] = useState(product.sideImg[0]);
  const [readMore, setReadMore] = useState(false);
  const truncatedDescription = `${product.details[1].slice(0, 262)}...`;

  useEffect(() => {
    setDynamicImage(product.sideImg[0]);
    setReadMore(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTab]);

  const toggleDescription = () => {
    setReadMore(!readMore);
  };

  const handleImageClick = (image) => {
    setDynamicImage(image);
  };

  return (
    <div className="container detailProductContainer">
      {isTab ? (
        <div className="tabContainer">
          <div className="tabImageContainer">
            <div className="imageContainer ">
              <div className="mainImage">
                <LazyLoadImage
                  src={dynamicImage}
                  effect="blur"
                  placeholderSrc={dynamicImage}
                ></LazyLoadImage>
                {/* <img src={dynamicImage} alt="" /> */}
              </div>
              <div className="sideImageContainer">
                {product.sideImg.map((img, i) => (
                  <div key={i} className="sideImageContainerImg">
                    <LazyLoadImage
                      className={dynamicImage === img ? "active" : ""}
                      effect="blur"
                      placeholderSrc={dynamicImage}
                      src={img}
                      alt={img}
                      onClick={() => {
                        handleImageClick(img);
                      }}
                    ></LazyLoadImage>
                  </div>
                ))}
              </div>
            </div>
            <div className="tabProductName">
              <div className="productType">
                <h3>{product.title}</h3>
                <p>{product.percentage}</p>
              </div>
              <h4>{product.amount}</h4>
              {/* <Button>Buy Now</Button> */}
            </div>
          </div>
          <div className="tabProductDetails">
            <div className="productFlavourSection">
              <div className="productFlavour">
                <img src={flower} alt="flower" />
                <p>{product.flavour}</p>
              </div>
              <div className="productFlavour">
                <img src={glass} alt="glass" />
                <p>{product.litre}</p>
              </div>
              <div className="productFlavour">
                <img src={flag} alt="flag" />
                <p>{product.madeIn}</p>
              </div>
            </div>

            <div className="productDescription">
              <p>
                {readMore ? (
                  <span>
                    {product.details.map((detail, index) => (
                      <span
                        key={index}
                        className={index === 0 ? "detailsNextLine" : ""}
                      >
                        {detail}
                      </span>
                    ))}
                  </span>
                ) : (
                  <span>
                    {product.details.map((detail, index) => (
                      <span
                        key={index}
                        className={index === 0 ? "detailsNextLine" : ""}
                      >
                        {index === 1 ? truncatedDescription : detail}
                      </span>
                    ))}
                  </span>
                )}

                <span className="readMore" onClick={toggleDescription}>
                  {readMore ? "read less" : "read more"}
                </span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="imageContainer">
            <div className="sideImageContainer">
              {product.sideImg.map((img, i) => (
                <div key={i} className="sideImageContainerLogo">
                  <img
                    className={dynamicImage === img ? "active" : ""}
                    src={img}
                    alt={img}
                    onClick={() => {
                      handleImageClick(img);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="mainImage">
              {/* <img src={dynamicImage} alt="" /> */}
              <LazyLoadImage
                src={dynamicImage}
                effect="blur"
                placeholderSrc={dynamicImage}
              ></LazyLoadImage>
            </div>
          </div>
          <div className="productDetails">
            <div className="productHeading">
              <div className="productName">
                <h3>{product.title}</h3>
                <p>{product.percentage}</p>
              </div>
              <div className="productRate">
                <h3>{product.amount}</h3>
              </div>
            </div>
            <div className="descriptionWrap">
              <div className="productDescription">
                <p>
                  {readMore ? (
                    <span>
                      {product.details.map((detail, index) => (
                        <span
                          key={index}
                          className={index === 0 ? "detailsNextLine" : ""}
                        >
                          {detail}
                        </span>
                      ))}
                    </span>
                  ) : (
                    <span>
                      {product.details.map((detail, index) => (
                        <span
                          key={index}
                          className={index === 0 ? "detailsNextLine" : ""}
                        >
                          {index === 1 ? truncatedDescription : detail}
                        </span>
                      ))}
                    </span>
                  )}
                  <span className="readMore" onClick={toggleDescription}>
                    {readMore ? " read less" : " read more"}
                  </span>
                </p>
              </div>

              <div className="flowerButtonWrap">
                <div className="productFlavourSection">
                  <div className="productFlavour">
                    <img src={flower} alt="flower" />
                    <p>{product.flavour}</p>
                  </div>
                  <div className="productFlavour">
                    <img src={glass} alt="glass" />
                    <p>{product.litre}</p>
                  </div>
                  <div className="productFlavour">
                    <img src={flag} alt="flag" />
                    <p>{product.madeIn}</p>
                  </div>
                </div>
                {/* <Button>Buy Now </Button> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetail;
