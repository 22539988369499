import React, { useEffect } from "react";
import { BASE_IMAGE_URL } from "utils/constants";

import "containers/Home/home.scss";

const TharraModal = ({ isModalOpen, setIsModalOpen, setShowCookie }) => {
  const tharraLogo = `${BASE_IMAGE_URL}/home/modalTharraLogo.svg`;

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isModalOpen]);

  const preventShowAgainModal = (e) => {
    if (e.target.checked) localStorage.setItem("hideModal", "true");
  };

  const handleModal = () => {
    setIsModalOpen(false);
    localStorage.setItem("preventModalshowing", "true");
    if (
      localStorage.getItem("acceptAllCookies") !== null ||
      localStorage.getItem("acceptSelectedCookies") !== null
    ) {
      return;
    }

    localStorage.setItem("showCookie", "true");

    setShowCookie(true);
  };

  if (!isModalOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="modal">
        <div className="modalTopBox">
          <div className="tharraLogoBox">
            <img src={tharraLogo} alt="ModalTharraLogo" />
          </div>
          <p id="wellComeTharra">WELCOME TO THARRA</p>
          <div className="headingBox">
            <h1>Are you 21 or older?</h1>
            <h3>
              Access to our website requires being of legal drinking age in your
              country, or over 21 if no legal age is specified.
            </h3>
          </div>
          <div className="rememberMeBox">
            <div className="checkBox">
              <input
                type="checkbox"
                onChange={preventShowAgainModal}
                //  style={{ width: "16px", height: "16px" }}
              />
              Remember me
            </div>
            <p>Do not tick the box if the device is shared.</p>
          </div>
          <p id="tharraSuggetion">
            By pressing "Yes, Enter," you confirm you meet age requirements,
            agree to Terms and Conditions, and acknowledge reading the Privacy &
            Cookies notice.
          </p>
          <button onClick={handleModal}>Yes, Enter</button>
        </div>
        <div className="modalBottomBox">
          <p>
            THE ABUSE OF ALCOHOL IS DANGEROUS FOR YOUR HEALTH. DRINK RESPONSIBLY
          </p>
        </div>
      </div>
    </div>
  );
};

export default TharraModal;
