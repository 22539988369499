import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { Link } from "react-router-dom";
import { BASE_IMAGE_URL } from "utils/constants";

import useScreenMobile from "hooks/useScreenMobile";

// import EmailUpdate from "components/EmailUpdates/EmailUpdate";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import "containers/VisitUs/visitUs.scss";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWppdGh2YzQ1IiwiYSI6ImNsd2tkNTIwejEyN2Uya21tZG80c3h6ZnEifQ.ieFvEHyY945cFmFJEgUDBw";

const predefinedLocations = [
  {
    name: "Redmond Ridge Spirits LLC",
    text: "18001 NE 76th St #140, Redmond, WA 98052, United States",
    lat: 47.69013,
    lng: -122.04335,
    time: "Mon - Sat . 11:00 AM - 07:00 PM",
    number: "+1 425-868-5300"
  },
  {
    name: "Mint Progressive Indian",
    text: "1103 1st Ave, Seattle, WA 98101, United States",
    lat: 47.6053365,
    lng: -122.337284,
    time: "Mon - Sun . 11:00 AM - 09:30 PM",
    number: "+1 206-533-3399"
  }
];

const VisitUs = () => {
  const downArrow = `${BASE_IMAGE_URL}/visitUs/searchIcon.svg`;
  const drinkMarker = `${BASE_IMAGE_URL}/visitUs/drink.svg`;
  const blackArrow = `${BASE_IMAGE_URL}/visitUs/blackArrow.svg`;
  const clock = `${BASE_IMAGE_URL}/visitUs/clock.svg`;
  const phone = `${BASE_IMAGE_URL}/visitUs/phone.svg`;

  const isTab = useScreenMobile({ size: 992 });
  const isMobile = useScreenMobile({ size: 568 });
  const [pinCode, setPinCode] = useState("");
  const [places, setPlaces] = useState(null);
  const [suggestionPlace, setSuggestionPlace] = useState(false);
  const [latGet, setLatGet] = useState("");
  const [relatedPlaces, setRealtedPlaces] = useState([]);
  const [suggestionSearch, setSuggestionSearch] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [dropDownClick, setDropDownClick] = useState(false);
  const [streetName, setStreetName] = useState("");

  console.log(dropDownClick, "dropDownClick");
  const checkNotFound = (latGet) => {
    const noFound = predefinedLocations.find((itm) => {
      return itm.lat.toString().slice(0, 2) === latGet.toString().slice(0, 2);
    });

    if (noFound) {
      setNotFound(false);
    } else {
      setNotFound(true);
    }
  };

  useEffect(() => {
    const fectRealtedPlaces = () => {
      const tempData = [...predefinedLocations];

      let data = tempData.filter((item, index) => {
        return (
          String(item.lat).substring(0, 2) === String(latGet).substring(0, 2)
        );
      });
      setRealtedPlaces(data);
    };

    if (latGet) {
      fectRealtedPlaces();
    }
  }, [latGet]);

  const state = {
    lng: -95.006,
    lat: 40.7128,
    zoom: 3
  };

  const tabletState = {
    lng: -95.006,
    lat: 40.7128,
    zoom: 1.5
  };

  const mobileState = {
    lng: -95.006,
    lat: 40.7128,
    zoom: 2
  };

  const mapContainer = useRef(null);
  const map = useRef(null);

  const style = {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  };

  useEffect(() => {
    if (map.current) {
      map.current.remove();
      map.current = null;
    }

    const initialState = isTab ? tabletState : isMobile ? mobileState : state;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [initialState?.lng, initialState?.lat],
      zoom: initialState.zoom
    });

    // Add markers for each predefined location
    predefinedLocations.forEach((location) => {
      const marker = new mapboxgl.Marker({
        // Use custom marker image
        element: createCustomMarker(location.text)
      })
        .setLngLat([location.lng, location.lat])
        .setPopup(
          new mapboxgl.Popup().setHTML(`
            <div class="headingContainer">
            <h3> ${location.name}</h3>
            <p>${location.text}</p>
            </div>
            <div class="mapPopupContainer">
            <div class="timeContainer">
            <img  class="clock" src="${clock}" alt="Location Image" />
            <p>${location.time}</p>
            </div>

            <div class="timeContainer">
            <img class="phone" src="${phone}" alt="Phone Image" />
            <p>${location.number}</p>

            </div>
            </div>

            <a href="https://www.google.com/maps?q=${location.lat},${location.lng}" target="_blank">

              <button class="getDirectionButton">
                Get Direction <img src="${blackArrow}" alt="" />
              </button>
            </a>
          `)
        )
        .addTo(map.current);

      marker.getElement().addEventListener("click", () => {
        setSuggestionPlace(true);
        setDropDownClick(true);
        checkNotFound(location.lat);
        setLatGet(location.lat);
        flyToLocation(location);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lat, state.lng, state.zoom, isTab]);

  const createCustomMarker = (text) => {
    const markerElement = document.createElement("div");
    markerElement.className = "custom-marker";
    markerElement.innerHTML = `<img src="${drinkMarker}" alt="${text}" />`;
    return markerElement;
  };

  const flyToLocation = (location) => {
    // setSuggestionSearch(true);
    setLatGet(location.lat);
    checkNotFound(location.lat);
    // todo
    // console.log(location, "location");

    if (!location || !location.lat || !location.lng) {
      console.error("Invalid location object:", location);
      return;
    }
    const { lng, lat } = location;

    map.current.flyTo({
      center: [lng, lat],
      zoom: 9
    });

    // Remove existing sub-location markers if any
    if (map.current.subLocationMarkers) {
      map.current.subLocationMarkers.forEach((marker) => marker.remove());
    }

    // Add markers for sub-locations if available
    if (location.subLocations) {
      map.current.subLocationMarkers = location.subLocations.map(
        (subLocation) => {
          const marker = new mapboxgl.Marker({
            element: createCustomMarker(subLocation.text)
          })
            .setLngLat([subLocation.lng, subLocation.lat])
            .setPopup(
              new mapboxgl.Popup().setHTML(`
                <h3>${subLocation.text}</h3>
                <button onclick="window.location.href='https://www.google.com/maps?q=${subLocation.lat},${subLocation.lng}'">
                  Get Direction <img src=${blackArrow} alt="" />
                </button>
              `)
            )
            .addTo(map.current);

          return marker;
        }
      );
    }
  };

  const resetMap = () => {
    map.current.flyTo({
      center: [state.lng, state.lat],
      zoom: state.zoom
    });

    // Remove existing sub-location markers if any
    if (map.current.subLocationMarkers) {
      map.current.subLocationMarkers.forEach((marker) => marker.remove());
    }
  };

  const handleLocationChange = (event) => {
    const selectedValue = event.target.value;
    setStreetName(event.target.value);
    setDropDownClick(false);
    setSuggestionSearch(false);

    setPinCode(selectedValue);
    if (selectedValue === "") {
      resetMap();
    } else {
      // Extract longitude and latitude from selected place
      const selectedPlace = places?.features?.find(
        (place) => place.place_name === selectedValue
      );
      if (selectedPlace) {
        const [lng, lat] = selectedPlace.center;
        flyToLocation({ lng, lat });
      }
    }
  };

  const getPlaces = async (pinCode) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${pinCode}.json?access_token=${mapboxgl.accessToken}`
      );
      const data = await response.json();

      setPlaces(data);
    } catch (error) {
      console.error("Error fetching places:", error);
    }
  };

  useEffect(() => {
    if (pinCode) {
      getPlaces(pinCode);
    }
  }, [pinCode]);

  const handleStreetName = (streetName) => {
    const firstCommaIndex = streetName.indexOf(",");
    const streetNameBeforeComma = streetName.substring(0, firstCommaIndex);
    setStreetName(streetNameBeforeComma);
  };
  return (
    <section className="visitUs">
      <div className="container">
        <div className="commonHeading visitUsHeading">
          <h2>Places to get Tharra Near You</h2>
          <p>
            Discover a diverse array of retailers, bars, and restaurants
            offering Tharra based on your location.
          </p>
        </div>
        <div className="visitUsMap">
          <div className="visitUsMapSearch">
            <div className="inputSearch">
              <input
                id="inputCountry"
                type="text"
                placeholder="Search Your City / Zipcode"
                value={streetName ? streetName : pinCode}
                onChange={handleLocationChange}
                onClick={() => {
                  setDropDownClick(false);
                  setNotFound(false);
                  setSuggestionPlace(false);
                  setSuggestionSearch(false);
                  const input = document.getElementById("inputCountry");
                  if (input) {
                    input.focus();
                    input.setSelectionRange(
                      input.value.length,
                      input.value.length
                    );
                  }
                }}
              />
              <img src={downArrow} alt="downArrow" />
            </div>
            {(streetName || pinCode) &&
            places &&
            places.features?.length > 0 ? (
              !suggestionPlace && (
                <>
                  {!isMobile && (
                    <div className="visitUsMapPlace">
                      {places.features.map((place, index) => (
                        <p
                          key={index}
                          onClick={() => {
                            handleStreetName(place?.place_name);
                            setDropDownClick(false);
                            setNotFound();
                            setSuggestionSearch(true);
                            setSuggestionPlace(true);
                            flyToLocation({
                              lng: place.center[0],
                              lat: place.center[1]
                            });
                          }}
                        >
                          {place?.place_name}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              )
            ) : (
              <>
                {suggestionPlace && relatedPlaces.length > 0 && (
                  <>
                    {!isMobile && (
                      <div className="mapScrollContainer">
                        {relatedPlaces.map((item, index) => (
                          <div className="suggestionPlace" key={index}>
                            <div className="headingContainer">
                              <h3>{item.name}</h3>
                              <p>{item.text}</p>
                            </div>
                            <div className="mapPopupContainer">
                              <div className="timeContainer">
                                <img
                                  className="clock"
                                  src={clock}
                                  alt="ClockImage"
                                />
                                <p>{item.time}</p>
                              </div>
                              <div className="timeContainer">
                                <img
                                  className="phone"
                                  src={phone}
                                  alt="PhoneImage"
                                />
                                <p>{item.number}</p>
                              </div>
                            </div>
                            <Link
                              to={`https://www.google.com/maps?q=${item.lat},${item.lng}`}
                              target="_blank"
                            >
                              <button className="getDirectionButton">
                                Get Direction{" "}
                                <img src={blackArrow} alt="Arrow Icon" />
                              </button>
                            </Link>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {!isMobile && notFound && (
              <p className="visitUsMapPlaceNoData">No Results found</p>
            )}
            {suggestionSearch && !isMobile && (
              <>
                {!notFound && (
                  <div className="mapScrollContainer">
                    {relatedPlaces.map((item, index) => (
                      <div className="suggestionPlace" key={index}>
                        <div className="headingContainer">
                          <h3>{item.name}</h3>
                          <p>{item.text}</p>
                        </div>
                        <div className="mapPopupContainer">
                          <div className="timeContainer">
                            <img
                              className="clock"
                              src={clock}
                              alt="clockImage"
                            />
                            <p>{item.time}</p>
                          </div>
                          <div className="timeContainer">
                            <img
                              className="phone"
                              src={phone}
                              alt="phoneImage"
                            />
                            <p>{item.number}</p>
                          </div>
                        </div>
                        <Link
                          to={`https://www.google.com/maps?q=${item.lat},${item.lng}`}
                          target="_blank"
                        >
                          <button className="getDirectionButton">
                            Get Direction{" "}
                            <img src={blackArrow} alt="Arrow Icon" />
                          </button>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className="visitUsMapSection"
            onClick={() => {
              setSuggestionPlace(true);
            }}
          >
            <div style={style} ref={mapContainer} />
          </div>

          {/* mobile */}
          {isMobile && (
            <>
              <div
                // className={`visitUsMapSearch ${dropDownClick && `dropdown`} `}
                className={`visitUsMapSearch ${dropDownClick ? "" : "dropdown"}`}
              >
                {!isMobile && (
                  <div className="inputSearch">
                    <input
                      type="text"
                      placeholder="Enter Pin-Code"
                      value={streetName ? streetName : pinCode}
                      onChange={() => {
                        handleLocationChange();
                      }}
                      onClick={() => {
                        setSuggestionPlace(false);
                        // setSuggestionSearch(false);
                      }}
                    />
                    <img src={downArrow} alt="downArrow" />
                  </div>
                )}

                {(streetName || pinCode) &&
                places &&
                places.features?.length > 0 ? (
                  !suggestionPlace && (
                    <div className={`visitUsMapPlace  `}>
                      {places.features.map((place, index) => (
                        <p
                          key={index}
                          onClick={() => {
                            handleStreetName(place?.place_name);
                            setDropDownClick(true);
                            setSuggestionPlace(true);
                            setSuggestionSearch(true);
                            flyToLocation({
                              lng: place.center[0],
                              lat: place.center[1]
                            });
                          }}
                        >
                          {place?.place_name}
                        </p>
                      ))}
                    </div>
                  )
                ) : (
                  <>
                    {suggestionPlace && relatedPlaces.length > 0 && (
                      <>
                        {isMobile && (
                          <div className="mapScrollContainer">
                            {relatedPlaces.map((item, index) => (
                              <div className="suggestionPlace" key={index}>
                                <div className="headingContainer">
                                  <h3>{item.name}</h3>
                                  <p>{item.text}</p>
                                </div>
                                <div className="mapPopupContainer">
                                  <div className="timeContainer">
                                    <img
                                      className="clock"
                                      src={clock}
                                      alt="ClockImage"
                                    />
                                    <p>{item.time}</p>
                                  </div>
                                  <div className="timeContainer">
                                    <img
                                      className="phone"
                                      src={phone}
                                      alt="PhoneImage"
                                    />
                                    <p>{item.number}</p>
                                  </div>
                                </div>
                                <Link
                                  to={`https://www.google.com/maps?q=${item.lat},${item.lng}`}
                                  target="_blank"
                                >
                                  <button className="getDirectionButton">
                                    Get Direction{" "}
                                    <img src={blackArrow} alt="Arrow Icon" />
                                  </button>
                                </Link>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {isMobile && (
                  <>
                    {notFound && (
                      <p className="visitUsMapPlaceNoData">No Results found</p>
                    )}
                    {suggestionSearch && (
                      <>
                        {!notFound && (
                          <div className="mapScrollContainer">
                            {relatedPlaces.map((item, index) => (
                              <div className="suggestionPlace" key={index}>
                                <div className="headingContainer">
                                  <h3>{item.name}</h3>
                                  <p>{item.text}</p>
                                </div>
                                <div className="mapPopupContainer">
                                  <div className="timeContainer">
                                    <img
                                      className="clock"
                                      src={clock}
                                      alt="clockImage"
                                    />
                                    <p>{item.time}</p>
                                  </div>
                                  <div className="timeContainer">
                                    <img
                                      className="phone"
                                      src={phone}
                                      alt="phoneImage"
                                    />
                                    <p>{item.number}</p>
                                  </div>
                                </div>
                                <Link
                                  to={`https://www.google.com/maps?q=${item.lat},${item.lng}`}
                                  target="_blank"
                                >
                                  <button className="getDirectionButton">
                                    Get Direction{" "}
                                    <img src={blackArrow} alt="Arrow Icon" />
                                  </button>
                                </Link>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

// const VisitUs = () => {
//   const mapImg = `${BASE_IMAGE_URL}/visitUs/visitUsMapImgDesktp.webp`;
//   const mapMobile = `${BASE_IMAGE_URL}/visitUs/visitUsMobileImg.webp`;
//   const mapTab = `${BASE_IMAGE_URL}/visitUs/visitUsTabImg.webp`;
//   const isTab = useScreenMobile({ size: 768 });
//   const isMobile = useScreenMobile({ size: 568 });

//   return (
//     <section className="visitUs">
//       <div className="container visitUsContainer">
//         <div className="visitUsContainerTop">
//           <h1>Places to get {isMobile && <br />} Tharra Near You</h1>
//           <p>
//             Discover a diverse array of retailers, bars, and restaurants
//             offering Tharra based on your location.
//           </p>
//         </div>
//         <div className="visitUsContainerBottom">
//           <div className="subscribeBox">
//             <EmailUpdate visit={true} />
//           </div>
//           <div className="mapImageBox">
//             <LazyLoadImage
//               src={
//                 !isTab && !isMobile ? mapImg : !isMobile ? mapMobile : mapTab
//               }
//               effect="blur"
//               placeholderSrc={
//                 !isTab && !isMobile ? mapImg : !isMobile ? mapMobile : mapTab
//               }
//             ></LazyLoadImage>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

export default VisitUs;
