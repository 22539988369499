import Banner from "components/Banner/index";
import CocktailsList from "containers/OurCocktails/CocktailsList";
import EndLessCocktail from "containers/OurCocktails/EndLessCocktail";

import { ourCocktails } from "constants/bannerDetails";

import "containers/OurCocktails/ourCocktails.scss";

const OurCocktails = () => {
  return (
    <>
      <Banner
        desktopBannerVideo={ourCocktails?.desktopBannerVideo}
        tabBannerVideo={ourCocktails?.tabBannerVideo}
        mobileBannerVideo={ourCocktails?.mobileBannervideo}
        heading={ourCocktails?.heading}
        paragraph={ourCocktails?.paragraph}
        bannerHeading="ourCocktailsHeading"
        desktopContainer="ourCocktailsDesktopContainer"
      />
      <CocktailsList />
      <EndLessCocktail />
    </>
  );
};

export default OurCocktails;
