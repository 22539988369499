import React, { useState } from "react";
import { BASE_IMAGE_URL } from "utils/constants";

import Button from "components/Button";

// import checkCircle from "assets/images/home/checkCircle.svg";

import "components/EmailUpdates/emailUpdate.scss";
import useScreenMobile from "hooks/useScreenMobile";

const Email = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

const EmailUpdate = ({ btn, color, marginB, visit }) => {
  const isMobile = useScreenMobile({ size: 568 });

  const [footerMail, setFooterMail] = useState({
    email: "",
    loading: false,
    subscribed: false
  });
  const [error, setError] = useState({
    emptyEmail: false,
    invalidEmail: false,
    alreadySubscribed: false
  });
  const [buttonText, setButtonText] = useState(
    visit ? "Get Notified" : "Subscribe"
  );

  const handleSubmit = async () => {
    const enteredEmail = footerMail.email.toLowerCase();

    if (!enteredEmail) {
      setError({
        emptyEmail: true,
        invalidEmail: false,
        alreadySubscribed: false
      });
      return;
    }

    if (!Email.test(enteredEmail)) {
      setError({
        emptyEmail: false,
        invalidEmail: true,
        alreadySubscribed: false
      });
      return;
    }

    setFooterMail((prev) => ({ ...prev, loading: true }));

    try {
      // Make a GET request to check if the email is already subscribed
      const checkResponse = await fetch(
        "https://script.googleusercontent.com/macros/echo?user_content_key=Tlfkuqya-NoeUOnZ1VuFPW3c-SgThfid_lqI0b5zzHoTKb9kpNntPrQ_yr2Dl8m1ITwWXzYoqMDGqYBn8N5lflr8DofGFiC7m5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnOuYfuyI9Bb26YFSGYf3zjaOrLS89MahxrKB4qFusD9RbUS1P_ARasdyZ69NhQ9RBW7ASg91AtGTt9TY-6XcYC5uFkKyhCnu19z9Jw9Md8uu&lib=MS2WKx_PUIhKvsDs7NGyDNTOlhnOnhyG2"
      );
      const checkData = await checkResponse.json();
      const subscribedEmails = checkData.data.map((item) =>
        item.email.toLowerCase()
      );
      if (subscribedEmails.includes(enteredEmail)) {
        setError({
          emptyEmail: false,
          invalidEmail: false,
          alreadySubscribed: true
        });
        setFooterMail((prev) => ({ ...prev, loading: false }));
        return;
      }

      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxwlSSuuYSzdZqt6wMoI4YWPCgk84Oc3W9Fl2zeRLKcmuNE_eQ9ZLGxwOU7QSBSiLDK7g/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ email: enteredEmail }),
          mode: "no-cors"
        }
      );

      if (response.ok === false) {
        setFooterMail({ email: "", loading: false, subscribed: true });
        setButtonText("Subscribed");

        setTimeout(() => {
          setFooterMail((prev) => ({ ...prev, subscribed: false }));
          setButtonText(visit ? "Get Notified" : "Subscribe");
        }, 4000);

        // Send data to Sheet 1
        const sheet1Data = {
          email: enteredEmail
          // Add any other data you want to send
        };
        await fetch("https://script.google.com/macros/d/SCRIPT_ID_1/exec", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(sheet1Data),
          mode: "no-cors"
        });

        // Send data to Sheet 2
        const sheet2Data = {
          email: enteredEmail
          // Add any other data you want to send
        };
        await fetch("https://script.google.com/macros/d/SCRIPT_ID_2/exec", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(sheet2Data),
          mode: "no-cors"
        });
      } else {
        setFooterMail((prev) => ({ ...prev, loading: false }));
        setButtonText("Subscribe");
      }
    } catch (error) {
      console.error("Subscription failed: ", error);
      setFooterMail((prev) => ({ ...prev, loading: false }));
      setButtonText("Subscribe");
    }
  };

  return (
    <div
      style={{ marginBottom: marginB }}
      className={
        color ? "emailBox color" : visit ? "emailBox visitBox" : "emailBox"
      }
    >
      {visit ? (
        <h3>
          Coming soon at a store
          {!isMobile && <br />} near you!
        </h3>
      ) : (
        <h3>Subscribe For Email Updates</h3>
      )}
      {visit ? (
        <p>
          Subscribe now to get notified about Tharra's launch at a store near
          you soon!
        </p>
      ) : (
        <p>
          Stay in the loop with essential email updates. We
          <br /> respect your inbox and won't spam you!
        </p>
      )}

      <div
        style={{ flexDirection: visit && "column", gap: "24px" }}
        className="emailSubscribeBox"
      >
        <input
          type="email"
          placeholder="Enter your email"
          value={footerMail.email}
          onChange={(e) => {
            setFooterMail({ ...footerMail, email: e.target.value });
            setError({
              emptyEmail: false,
              invalidEmail: false,
              alreadySubscribed: false
            });
          }}
        />

        <Button
          className={btn}
          onClick={handleSubmit}
          disabled={footerMail.loading}
          loading={footerMail.loading}
          visit={true}
        >
          {buttonText}
        </Button>

        {error.emptyEmail && (
          <span className="emailError">This field is required</span>
        )}

        {error.invalidEmail && (
          <span className="emailError">
            Please enter a valid email address.
          </span>
        )}

        {error.alreadySubscribed && (
          <span className="emailError">
            Subscription already exists for this email.
          </span>
        )}

        {footerMail.subscribed && (
          <span className="subscriptionMessage emailSuccess">
            <img
              src={`${BASE_IMAGE_URL}/home/checkCircle.svg`}
              alt="checkCircle"
            />{" "}
            You are subscribed to email updates
          </span>
        )}
      </div>
    </div>
  );
};

export default EmailUpdate;
