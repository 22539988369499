export const smoothNavigation = (sectionId, customOffsets = {}) => {
  const defaultOffset = -50;
  const headerOffset = customOffsets[sectionId] || defaultOffset;
  const element = document.getElementById(sectionId);
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
};
