import { smoothNavigation } from "utils/scrollBehaviour";
import { Link } from "react-router-dom";

import Button from "components/Button";
import FadeUp from "components/FadeUp";

const EndLessCocktail = () => {
  return (
    <section className="mixing container">
      <div className="mixingImage">
        <div className="mixingContent">
          <FadeUp>
            <div className="commonHeading">
              <h2>Start Mixing</h2>
              <p>Explore diverse spirits for endless cocktail possibilities.</p>
            </div>
          </FadeUp>
          <Link to={"/our-spirits/#ourProduct"}>
            <div
              className="exploreBtn"
              onClick={() => {
                setTimeout(() => {
                  smoothNavigation("ourProduct", { ourProduct: 59 });
                }, 300);
              }}
            >
              <Button>Explore Products</Button>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default EndLessCocktail;
