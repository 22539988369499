// import jamunProduct from "assets/images/ourProducts/jamunBottle.webp";
// import vodkaProduct from "assets/images/ourProducts/vodkaBottle.webp";
// import paaniProduct from "assets/images/ourProducts/paaniBottle.webp";
// import baelProduct from "assets/images/ourProducts/baelBottle.webp";

// import jamunFullImage from "assets/images/productDetails/jamun/jamunFullImage.webp";
// import jamunHalfImage from "assets/images/productDetails/jamun/jamunHalfImage.webp";
// import jamunVideoImage from "assets/images/productDetails/jamun/jamunVideoImage.webp";
// import jamunDetailImage from "assets/images/productDetails/jamun/jamunDetailImage.webp";

// import baelFullImage from "assets/images/productDetails/bael/baelFullImage.webp";
// import baelHalfImage from "assets/images/productDetails/bael/baelHalfImage.webp";
// import baelVideoImage from "assets/images/productDetails/bael/baelVideoImage.webp";
// import baelDetailImage from "assets/images/productDetails/bael/baelDetailImage.webp";

// import paaniFullImage from "assets/images/productDetails/paani/paaniFullImage.webp";
// import paaniHalfImage from "assets/images/productDetails/paani/paaniHalfImage.webp";
// import paaniVideoImage from "assets/images/productDetails/paani/paaniVideoImage.webp";
// import paaniDetailImage from "assets/images/productDetails/paani/paaniDetailImage.webp";

// import potatoFullImage from "assets/images/productDetails/potato/potatoFullImage.webp";
// import potatoHalfImage from "assets/images/productDetails/potato/potatoHalfImage.webp";
// import potatoVideoImage from "assets/images/productDetails/potato/potatoVideoImage.webp";
// import potatoDetailImage from "assets/images/productDetails/potato/potatoDetailImage.webp";

import { BASE_IMAGE_URL } from "utils/constants";

export const ourProducts = [
  {
    id: 1,
    image: `${BASE_IMAGE_URL}/ourProducts/jamunBottle.webp`,
    title: "Tharra Jamun",
    linkName: "tharra-jamun",
    percentage: "36% ALC./VOL",
    amount: "$34.99",
    details: [
      "Vodka infused with jamun fruit, a touch of sweetness balanced by whispers of exotic spice.",
      "Revered by emperors and deities, the Jamun fruit is now exquisitely reinvented for the discerning palate. Its signature taste, enriched with a mélange of secret spices, melds seamlessly into the silky finish of our vodka to create a nectarous libation that is in a league of its own. With a sophisticated interplay of sweet and tart, our Jamun-infused vodka offers a complex flavor mosaic that feels both comfortingly familiar and thrillingly foreign. Each sip of our Jamun-infused vodka is a homage to tradition and a toast to the divine."
    ],

    description:
      "Jamun-infused vodka with sweetness tempered by whispers of exotic spice.",
    btnText: "View Details",
    sideImg: [
      `${BASE_IMAGE_URL}/productDetails/jamun/jamunFullImage.webp`,
      `${BASE_IMAGE_URL}/productDetails/jamun/jamunHalfImage.webp`,
      `${BASE_IMAGE_URL}/productDetails/jamun/jamunVideoImage.webp`
      // jamunDetailImage
    ],
    flavour: "Jamun flavoured ",
    madeIn: "Made in USA",
    litre: "750 Ml"
  },
  {
    id: 2,
    image: `${BASE_IMAGE_URL}/ourProducts/paaniBottle.webp`,
    title: "Tharra Paani",
    linkName: "tharra-paani",
    percentage: "36% ALC./VOL",
    amount: "$34.99",
    details: [
      "An invitation to indulge in the spirit of India's bustling lanes with our innovative creation — Paani Puri flavor infused vodka.",
      "Our vodka pays homage to the exhilarating rush of flavors that is the heart of paani puri. Each batch is infused with the symphony of tastes that define this beloved street food — the piquant zing of tamarind, the sharpness of mint, the gentle sweetness of date chutney, and a bold kick of spice that dances on the palate. Let the celebration of this culinary marvel unfold in your glass."
    ],

    description:
      "Experience India's bustling lanes with our Paani Puri-Infused Vodka creation.",
    btnText: "View Details",
    sideImg: [
      `${BASE_IMAGE_URL}/productDetails/paani/paaniFullImage.webp`,
      `${BASE_IMAGE_URL}/productDetails/paani/paaniHalfImage.webp`,
      `${BASE_IMAGE_URL}/productDetails/paani/paaniVideoImage.webp`
      // paaniDetailImage
    ],
    flavour: "Paani Puri flavoured ",
    madeIn: "Made in USA",
    litre: "750 Ml"
  },
  {
    id: 3,
    image: `${BASE_IMAGE_URL}/ourProducts/baelBottle.webp`,
    title: "Tharra Bael",
    linkName: "tharra-bael",
    percentage: "36% ALC./VOL",
    amount: "$34.99",
    details: [
      "Unveil the secret of the ancients with our exquisite Bael (Wood Apple) Infused Vodka with a hint of secret spices. ",
      " Imbibe the spirit of divinity with our Bael (Wood Apple)-Infused Vodka — a heavenly concoction crafted for those who seek to elevate their taste to the realms of the extraordinary. Each pour is a tribute to the Bael's complex character — an impeccable fusion of sour, sweet, and tangy, smoothly entwined with the pure, crisp notes of our finely distilled vodka. The result is a liquid symphony, an indulgence that transcends the ordinary, offering a rare and exquisite experience for the senses."
    ],

    description:
      "Unveil ancient secrets with our Bael (Wood Apple) Infused Vodka, spiced subtly.",
    btnText: "View Details",
    sideImg: [
      `${BASE_IMAGE_URL}/productDetails/bael/baelFullImage.webp`,
      `${BASE_IMAGE_URL}/productDetails/bael/baelHalfImage.webp`,
      `${BASE_IMAGE_URL}/productDetails/bael/baelVideoImage.webp`
      //  baelDetailImage
    ],
    flavour: "Bael flavoured ",
    madeIn: "Made in USA",
    litre: "750 Ml"
  },
  {
    id: 4,
    image: `${BASE_IMAGE_URL}/ourProducts/vodkaBottle.webp`,
    title: "Potato Vodka",
    linkName: "potato-vodka",
    percentage: "41% ALC./VOL",
    amount: "$21.99",
    details: [
      "Introducing our Plain Potato Vodka, a testament to the purity of nature and the artistry of distillation.",
      "   Crafted from the finest russet potatoes, known for their robust flavor and creamy texture, this vodka is a tribute to simplicity and quality. Our russet potatoes, carefully selected for their superior quality and taste, are the heart of this spirit. They lend our vodka a rich, full-bodied character that is remarkably smooth, with a subtle earthy sweetness that whispers of its noble origins."
    ],

    description:
      "Pure Potato Vodka crafted from finest russet potatoes for unparalleled quality.",
    btnText: "View Details",
    sideImg: [
      `${BASE_IMAGE_URL}/productDetails/potato/potatoFullImage.webp`,
      `${BASE_IMAGE_URL}/productDetails/potato/potatoHalfImage.webp`,
      `${BASE_IMAGE_URL}/productDetails/potato/potatoVideoImage.webp`
      // potatoDetailImage
    ],
    flavour: "Made from Potatoes ",
    madeIn: "Made in USA",
    litre: "750 Ml"
  }
];
