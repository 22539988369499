import React from "react";
import "components/Input/input.scss";

const Input = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  name,
  onKeyDown,
  onBeforeInput,
  pattern,
  inputMode,
  onInput, // onInput instead of oninput
  maxLength, // Add maxLength to the destructuring
  ...restProps
}) => {
  return (
    <div className="inputContainer">
      <span>{label}</span>
      <input
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBeforeInput={onBeforeInput}
        autoComplete="off"
        pattern={pattern}
        inputMode={inputMode} // Change to camelCase
        onInput={onInput} // Change to camelCase
        maxLength={maxLength} // Add maxLength to the input element
        {...restProps}
      />
    </div>
  );
};

export default Input;
