// import jamun from "assets/images/header/jamun.webp";
// import paani from "assets/images/header/paani.webp";
// import bael from "assets/images/header/bael.webp";
// import vodka from "assets/images/header/vodka.webp";

import { BASE_IMAGE_URL } from "utils/constants";

export const navBarMobile = [
  {
    navBarLinkName: "HOME",
    link: "/"
  },
  {
    navBarLinkName: "OUR STORY",
    link: "/our-story"
  },
  {
    navBarLinkName: "OUR SPIRITS",
    link: "/our-spirits"
  },
  {
    navBarLinkName: "OUR COCKTAILS",
    link: "/our-cocktail"
  },
  {
    navBarLinkName: "CONTACT US",
    link: "/contact-us"
  },
  {
    navBarLinkName: "FIND US NEARBY",
    link: "/visit-us"
  }
];

export const ourSpirit = [
  {
    navBarLinkName: "Go back",
    className: "navBarLinksBackBtn"
  },
  {
    navBarLinkName: "Tharra Jamun",
    image: `${BASE_IMAGE_URL}/header/jamun.webp`,
    link: "/our-spirits/tharra-jamun"
  },
  {
    navBarLinkName: "Tharra Paani",
    image: `${BASE_IMAGE_URL}/header/paani.webp`,
    link: "/our-spirits/tharra-paani"
  },
  {
    navBarLinkName: "Tharra Bael",
    image: `${BASE_IMAGE_URL}/header/bael.webp`,
    link: "/our-spirits/tharra-bael"
  },
  {
    navBarLinkName: " Potato Vodka",
    image: `${BASE_IMAGE_URL}/header/vodka.webp`,
    link: "/our-spirits/potato-vodka"
  }
];
