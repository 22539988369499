// import tharraPaaniSurprise from "assets/images/cocktailCard/tharraPaaniSurprise.webp";
// import tharraPaaniPunch from "assets/images/cocktailCard/tharraPaaniPunch.webp";
// import tharraJamunFizz from "assets/images/cocktailCard/tharraJamunFizz.webp";
// import tharraBealPaaniPunch from "assets/images/cocktailCard/tharraBaelPaaniPunch.webp";
// import tharraBaelBloom from "assets/images/cocktailCard/tharraBaelBloom.webp";
// import potatoBasilBliss from "assets/images/cocktailCard/potatoBasilBliss.webp";
// import tharrajamunkidukaan from "assets/images/cocktailCard/tharrajamunkidukaan.webp";
// import tharrapaanikaghol from "assets/images/cocktailCard/tharrapaanikaghol.webp";
// import tharrabaelkakhel from "assets/images/cocktailCard/tharrabaelkakhel.webp";
// import themoscowmuletwist from "assets/images/cocktailCard/themoscowmuletwist.webp";
// import tharraspritzer from "assets/images/cocktailCard/tharraspritzer.webp";
// import tharragimlet from "assets/images/cocktailCard/tharragimlet.webp";

// import tharraBaelIcon from "assets/images/cocktailCard/tharraBaelIcon.webp";
// import tharraJamunIcon from "assets/images/cocktailCard/tharraJamunIcon.webp";
// import tharraPaaniIcon from "assets/images/cocktailCard/tharraPaaniIcon.webp";
// import tharraPotatoIcon from "assets/images/cocktailCard/tharraPotatoIcon.webp";

import { BASE_IMAGE_URL } from "utils/constants";

export const cocktailListData = [
  {
    id: 1,
    name: "Tharra Bael Bloom",
    linkName: "tharra-bael-bloom",
    category: "Bael",
    madeWith: "Tharra Bael Vodka",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharraBaelBloom.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraBaelIcon.webp`,
    description1:
      "Tharra Bael Bloom combines the smoothness of Tharra Bael Vodka with the delicate floral essence of Elderflower Liqueur. Fresh lemon juice and a touch of simple syrup provide a harmonious balance of sweetness and tartness.",
    description2:
      "For added depth, Angostura Bitters may be included. Garnished with an edible flower and a twist of lemon, this cocktail offers a sophisticated sensory experience, perfect for those seeking refined elegance in their libations.",
    ingredients: [
      { value: "2 oz", name: "Tharra Bael Vodka" },
      { value: "1 oz", name: "Elderflower Liqueur (St-Germain or similar)" },
      { value: "¾ oz", name: "Fresh Lime Juice" },
      { value: "½ oz", name: "Simple Syrup" },
      {
        value: "2 dashes",
        name: "Angostura Bitters (optional)"
      },
      {
        value: "",
        name: "Edible Flower (for garnish - pansy, viola, or lavender)"
      },
      { value: "", name: "Lemon Twist (for garnish)" }
    ],
    preparationListName: "Preparation",
    preparationList: [
      {
        value: "STEP 1",
        name: "In a shaker filled with ice, combine Tharra Bael vodka, elderflower liqueur, lemon juice, simple syrup, and Angostura bitters (if using)."
      },
      {
        value: "STEP 2",
        name: "Shake vigorously for 15 seconds."
      },
      {
        value: "STEP 3",
        name: "Strain into a chilled coupe glass."
      },
      {
        value: "STEP 4",
        name: "Garnish with a beautiful edible flower and a lemon twist."
      }
    ],
    tipsList: [
      "Adjust the simple syrup to your desired level of sweetness.",
      "If you don't have elderflower liqueur, you can substitute with another floral liqueur like rosewater or lavender liqueur, but be mindful of the overall flavor profile.",
      "Make sure to use edible flowers specifically grown for consumption."
    ]
  },
  {
    id: 2,
    name: "Tharra Bael  Punch",
    linkName: "tharra-bael-paani-punch",
    category: "Bael",
    madeWith: "Tharra Bael Vodka",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharraBaelPaaniPunch.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraBaelIcon.webp`,
    description1:
      "The cocktail balances the sweet and savory aspects of the Tharra Bael  vodka with the tartness of grapefruit and lime juice. The agave nectar adds a touch of sweetness, while the chipotle Tabasco provides a moderate chili kick.",
    description2:
      "The sea salt enhances the overall flavor profile, and the club soda adds a refreshing touch. The lime wedge and cilantro sprig provide a finishing touch of visual appeal and complement the drink's flavors.",

    ingredients: [
      { value: "2 oz", name: "Tharra Bael Vodka" },
      {
        value: "1 oz",
        name: "Jaggery Syrup (or substitute with simple syrup)"
      },
      { value: "¾ oz", name: "Fresh Lime Juice" },
      { value: "½ oz", name: "Imli (Tamarind) Pulp (strained)" },

      { value: "", name: "Pinch of Black Salt (Kala Namak)" },
      { value: "", name: "Splash of Soda Water (to top)" },
      { value: "", name: "Mint Sprig (for garnish)" }
    ],
    preparationListName: "Preparation",
    preparationList: [
      {
        value: "STEP 1",
        name: "In a small bowl, combine the jaggery syrup (or simple syrup) and a splash of hot water to create a thin consistency. Stir until the jaggery dissolves."
      },
      {
        value: "STEP 2",
        name: "In a shaker filled with ice, combine Tharra Bael vodka, lime juice, Imli pulp, and black salt."
      },
      {
        value: "STEP 3",
        name: "Shake vigorously for 15 seconds."
      },
      {
        value: "STEP 4",
        name: "Strain into a tall glass filled with fresh ice."
      },
      {
        value: "STEP 5",
        name: "Top with a splash of soda water for a light fizz."
      },
      {
        value: "STEP 6",
        name: "Garnish with a fresh mint sprig."
      }
    ],
    tipsList: [
      "Adjust the amount of jaggery syrup or simple syrup to your desired level of sweetness.",
      "If you prefer a spicier drink, muddle a few fresh green chilies with the Imli pulp before adding it to the shaker. ",
      "You can rim the glass with a mixture of black salt and chaat masala for an extra savory kick."
    ]
  },
  {
    id: 3,
    name: "Tharra Jamun Fizzz",
    linkName: "tharra-jamun-fizzz",
    category: "Jamun",
    madeWith: "Tharra Jamun Vodka",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharraJamunFizz.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraJamunIcon.webp`,
    description1:
      "Tharra Jamun Fizzz tantalizes the taste buds with the vibrant flavors of Tharra Jamun Vodka. Fresh lime juice and simple syrup create a perfect balance of tanginess and sweetness, while soda water adds effervescence for a refreshing finish.",
    description2:
      "Optionally, a sprinkle of chaat masala provides a spicy kick. Garnished with a fresh lemon wedge and a sprig of mint, this cocktail is a delightful twist on a classic fizz.",
    ingredients: [
      { value: "2 oz", name: "Tharra Jamun Vodka" },
      { value: "1 oz", name: "Fresh Lime Juice" },
      {
        value: "¾ oz",
        name: "Simple Syrup (or adjust to your sweetness preference)"
      },

      { value: "", name: "Soda Water (to top)" },
      { value: "", name: "Fresh Lemon Wedge (for garnish)" },
      { value: "", name: "Mint Sprig (for garnish)" },
      { value: "", name: "Chaat Masala (optional- for the masala kick)" }
    ],
    preparationListName: "Preparation",
    preparationList: [
      {
        value: "STEP 1",
        name: "In a shaker filled with ice, combine Tharra Jamun vodka, lime juice, and simple syrup."
      },
      {
        value: "STEP 2",
        name: "Shake vigorously for 15 seconds."
      },
      {
        value: "STEP 3",
        name: "Strain into a tall glass filled with fresh ice."
      },
      {
        value: "STEP 4",
        name: "Top with soda water."
      },
      {
        value: "STEP 5",
        name: "Garnish with a fresh Jamun wedge and a mint sprig."
      }
    ],
    tipsList: [
      "For a more intense Jamun flavor, muddle a few fresh Jamun berries in the shaker before adding the other ingredients.",
      "If you don't have simple syrup, you can make a quick substitute by dissolving equal parts sugar and water in a saucepan over low heat until the sugar dissolves.",
      "Adjust the amount of soda water to your preference for fizz level."
    ]
  },
  {
    id: 4,
    name: "Tharra Paani Punch",
    linkName: "tharra-paani-punch",
    category: "Paani",
    madeWith: "Tharra Paani Vodka",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharraPaaniPunch.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPaaniIcon.webp`,
    description1:
      "Tharra Paani Punch is a bold and flavorful cocktail featuring Tharra Paani Vodka. Freshly squeezed grapefruit and lime juices provide a zesty citrus base, while agave nectar adds a touch of sweetness.",
    description2:
      "Three dashes of chipotle Tabasco sauce deliver a moderate chili kick, balanced by a pinch of sea salt. Topped with a splash of club soda, and garnished with a lime wedge and cilantro sprig, this cocktail is a refreshing sensation with a hint of spice.",
    ingredients: [
      { value: "2 oz", name: "Tharra Paani  Vodka" },
      { value: "1 oz", name: "Freshly Squeezed Grapefruit Juice" },
      { value: "¾ oz", name: "Fresh Lime Juice" },
      { value: "½ oz", name: "Agave Nectar (or Simple Syrup)" },
      {
        value: "3 dashes",
        name: "Chipotle Tabasco Sauce (adjust to desired spice level)"
      },
      { value: "", name: "Pinch of Sea Salt" },
      { value: "", name: "Splash of Club Soda (to top)" },
      { value: "", name: "Lime Wedge (for garnish)" },
      { value: "", name: "Cilantro Sprig (for garnish)" }
    ],
    preparationListName: "Preparation",
    preparationList: [
      {
        value: "STEP 1",
        name: "In a shaker filled with ice, combine Tharra Paani  vodka, grapefruit juice, lime juice, agave nectar, chipotle Tabasco, and sea salt."
      },
      {
        value: "STEP 2",
        name: "Shake vigorously for 15 seconds."
      },
      {
        value: "STEP 3",
        name: "Strain into a tall glass filled with fresh ice."
      },
      {
        value: "STEP 4",
        name: "Top with a splash of club soda for a light fizz."
      },
      {
        value: "STEP 5",
        name: "Garnish with a lime wedge and a cilantro sprig."
      }
    ],
    tipsList: [
      "If you prefer a sweeter drink, increase the agave nectar or simple syrup to your taste.",
      "For a smokier flavor, substitute smoked paprika for the chipotle Tabasco.",
      "You can rim the glass with Tajin seasoning for an extra savory and spicy kick."
    ]
  },
  {
    id: 5,
    name: "Tharra Paani Surprise",
    linkName: "tharra-paani-surprise",
    category: "Paani",
    madeWith: "Tharra Paani Vodka",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharraPaaniSurprise.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPaaniIcon.webp`,
    description1:
      "Tharra Paani Surprise captivates with the bold flavors of Tharra Paani Puri Vodka, complemented by the luscious sweetness of fresh mango juice. The addition of strained Imli (Tamarind) Pulp and zesty cilantro chutney adds a burst of tangy excitement, while a pinch of chaat masala infuses a hint of spice.",
    description2:
      "Topped with a splash of soda water for effervescence and garnished with a puri shell and a vibrant cilantro sprig, this cocktail is a delightful sensory journey.",
    ingredients: [
      { value: "2 oz", name: "Tharra Paani Puri Vodka" },
      { value: "1 oz", name: "Fresh Mango Juice" },
      { value: "¾ oz", name: "Imli (Tamarind) Pulp (strained)" },
      { value: "½ oz", name: "Cilantro Chutney" },

      { value: "", name: "Pinch of Chaat Masala" },
      { value: "", name: "Splash of Club Soda (to top)" },
      { value: "", name: "Puri Shell (for garnish)" },
      { value: "", name: "Cilantro Sprig (for garnish)" }
    ],
    preparationListName: "Preparation",
    preparationList: [
      {
        value: "STEP 1",
        name: "Muddle mint with Imli pulp. Optionally, blend Imli for smoother texture before muddling."
      },
      {
        value: "STEP 2",
        name: "Shake Tharra Paani Puri vodka, mango juice, cilantro chutney, muddled Imli, and chaat masala."
      },
      {
        value: "STEP 3",
        name: "Shake vigorously for 15 seconds."
      },
      {
        value: "STEP 4",
        name: "Strain the mixture into a small, pre-chilled serving glass (like a small coupe glass)."
      },
      {
        value: "STEP 5",
        name: "Top with a splash of soda water for a light fizz."
      },
      {
        value: "STEP 6",
        name: "Carefully place a whole puri shell balanced on the rim of the glass."
      },
      {
        value: "STEP 7",
        name: "Garnish with a cilantro sprig."
      }
    ],
    tipsList: [
      "You can find Imli pulp (tamarind paste) in most Indian grocery stores.",
      "Cilantro chutney can also be found pre-made in Indian grocery stores, or you can make your own with fresh cilantro, mint, ginger, green chilies, and lemon juice.",
      "Adjust the amount of cilantro chutney based on your spice preference.",
      "The puri shell adds a playful element and allows you to enjoy the drink in a way that evokes the classic Paani Puri street food experience."
    ]
  },
  {
    id: 6,
    name: "Potato Basil Bliss",
    linkName: "potato-basil-bliss",
    category: "Potato",
    madeWith: "Tharra Potato Vodka",
    img: `${BASE_IMAGE_URL}/cocktailCard/potatoBasilBliss.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPaaniIcon.webp`,
    description1:
      "Unleash the rustic charm of Tharra Potato Vodka with a cocktail that's both bold and refined. Crafted from the finest potatoes, Tharra lends a distinct character to this classic concoction, making every sip an adventure into indulgence.",
    description2:
      "Elevate your drinking experience and savor the smooth, earthy notes of Tharra in this exquisite libation, perfect for any occasion. Whether you're hosting a sophisticated soirée or simply unwinding after a long day, this cocktail promises to tantalize your taste buds and leave you craving more.",
    ingredients: [
      { value: "2 oz", name: "Tharra Potato Vodka" },
      { value: "1 oz", name: "Fresh Lime Juice" },
      {
        value: "½ oz",
        name: "Simple Syrup (or adjust to your sweetness preference)"
      },
      { value: "3-4", name: "Fresh basil leaves" },
      { value: "2-3 ", name: "Slices of cucumber" },

      { value: "", name: "Splash of club soda" },
      { value: "", name: "Ice cubes" }
    ],
    preparationListName: "Preparation",
    preparationList: [
      {
        value: "STEP 1",
        name: "Muddle basil and cucumber slices in a shaker."
      },
      {
        value: "STEP 2",
        name: "Add Tharra Potato Vodka, lime juice, and simple syrup."
      },
      {
        value: "STEP 3",
        name: "Shake well with ice."
      },
      {
        value: "STEP 4",
        name: "Strain into a chilled glass over fresh ice."
      },
      {
        value: "STEP 5",
        name: "Top with a splash of club soda."
      }
    ],
    tipsList: [
      "Adjust sweetness by altering simple syrup quantity.",
      "Garnish with a cucumber slice for a refreshing touch.",
      "Experiment with different herbs like mint for varied flavors."
    ]
  },
  {
    id: 7,
    name: "Tharra Jamun Ki Dukaan",
    linkName: "tharra-jamun-ki-dukaan",
    category: "Jamun",
    madeWith: "Tharra Jamun Vodka",
    tagLine: "A Taste of Summer in Every Sip",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharrajamunkidukaan.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraJamunIcon.webp`,
    tagImg: `${BASE_IMAGE_URL}/cocktailCard/iceTag.svg`,
    tagName: "Thoda Ice thoda spice",
    description1:
      "This vibrant cocktail captures the essence of a warm Indian summer. Evoke memories of long, lazy days, and beating the heat with a cool, refreshing drink in your hand. Tharra Jamun Ki Dukaan brings those sensory delights to life in your glass.",
    description2:
      "Made with Tharra Jamun Vodka, a splash of soda water for refreshment, and a unique blend of chaat masala and black salt, this cocktail offers a symphony of tastes. Expect a burst of tangy sweetness from the jamun, balanced by the warmth of the spices and a subtle, savory hint from the black salt. Each sip is an adventure, a surprising and delightful exploration of Indian flavors.Whether you're hosting a lively party or enjoying a quiet escape, Tharra Jamun Ki Dukaan is sure to transport you with every mouthful.",
    ingredients: [
      { value: "2 oz", name: "Tharra Jamun Vodka" },
      { value: "1/4", name: "Tablespoon Chaat Masala" },

      { value: "", name: "A pinch of black Salt (kaala Namak)" },
      { value: "", name: "Soda Water (to top)" }
    ],
    preparationListName: "Instructions",
    preparationList: [
      {
        value: "STEP 1",
        name: "In a glass/shaker filled with ice, combine Tharra Jamun vodka, Chaat Masala and Black Salt"
      },
      {
        value: "STEP 2",
        name: "Stir gently for 15-20 seconds"
      },
      {
        value: "STEP 3",
        name: "Top with soda water."
      }
    ]
  },
  {
    id: 8,
    name: "Tharra Paani Ka Ghol",
    linkName: "tharra-paani-ka-ghol",
    category: "Paani",
    madeWith: "Tharra Panni Vodka",
    tagLine: "A Spicy Streetside Adventure in a Glass",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharrapaanikaghol.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPaaniIcon.webp`,
    tagImg: `${BASE_IMAGE_URL}/cocktailCard/iceTag.svg`,
    tagName: "Thoda Ice thoda spice",
    description1:
      "Ever wandered through India's vibrant streets, enticed by the aromas of street food vendors? Tharra Paani ka Ghol captures that experience in every sip. This flavorful cocktail embodies India’s essence. Imagine bustling markets, clouds of fragrant spices, and the tang of streetside treats. We've bottled that energy with Tharra Paani Vodka, black salt for a savory kick, and black pepper for warmth. The optional green chili adds an extra fiery layer, mimicking the surprise spice of a streetside chaat.",
    description2:
      "Tharra Paani ka Ghol is perfect for adventurers who crave robust flavors. It's ideal for social gatherings, adding a unique twist to your next party. So, take a sip, close your eyes, and let the flavors transport you to the heart of India's vibrant street food scene.",
    ingredients: [
      { value: "2 oz", name: "Tharra Paani Vodka" },
      { value: "1/4", name: "Tablespoon Black Salt (Kaala Namak)" },

      { value: "", name: "A pinch of black Pepper Powder" },
      { value: "", name: "Soda Water (to top)" },
      { value: "", name: "Add sliced half green chilly (Optional)" }
    ],
    preparationListName: "Instructions",
    preparationList: [
      {
        value: "STEP 1",
        name: "In a shaker filled with ice, combine Tharra Paani vodka, Chaat Masala and Black Salt"
      },
      {
        value: "STEP 2",
        name: "Stir gently for 15-20 seconds"
      },
      {
        value: "STEP 3",
        name: "Top with soda water"
      }
    ]
  },
  {
    id: 9,
    name: "Tharra Bael Ka Khel",
    linkName: "tharra-bael-ka-khel",
    category: "Bael",
    madeWith: "Tharra Bael Vodka",
    tagLine: "A Playful Twist on Tradition in Every Sip",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharrabaelkakhel.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraBaelIcon.webp`,
    tagImg: `${BASE_IMAGE_URL}/cocktailCard/iceTag.svg`,
    tagName: "Thoda Ice thoda spice",
    description1:
      "Tharra Bael ka Khel is more than just a cocktail; it's a playful dance on your taste buds, celebrating the unique twist this drink takes on a classic Indian flavor. Imagine the sweet and tangy bite of fresh lime, perfectly balanced by the earthy aroma of roasted cumin powder. A touch of black salt adds a surprising depth, creating a flavor profile that's both zesty and aromatic. Made with Tharra Bael Vodka as its star, this cocktail is a delightful exploration of tradition with a modern twist.",
    description2:
      "Whether you crave citrus or savory earthiness, Tharra Bael ka Khel balances both perfectly. Sweetened with simple syrup or jaggery, it's ideal for any occasion. Shake for a quick refresh, or savor slowly to enjoy the flavors.",
    ingredients: [
      { value: "2 oz", name: "Tharra Bael Vodka" },
      { value: "1/4", name: "Tablespoon roasted Cumin Power" },

      { value: "", name: "Fresh Lime Juice" },
      { value: "", name: "A pinch of black Salt (kaala Namak)" },
      { value: "", name: "Soda Water (to top)" },
      { value: "¼", name: "Spoon simple syrup/sugar/Jaggery" }
    ],
    preparationListName: "Instructions",
    preparationList: [
      {
        value: "STEP 1",
        name: "In a shaker filled with ice, combine Tharra Bael vodka, Lime Juice, Cumin powder and Black Salt"
      },
      {
        value: "STEP 2",
        name: "Shake vigorously for 15 seconds."
      },
      {
        value: "STEP 3",
        name: "Top with soda water."
      }
    ]
  },
  {
    id: 10,
    name: "The Moscow Mule Twist",
    linkName: "the-moscow-mule-twist",
    category: "Potato",
    madeWith: "Tharra Potato Vodka",
    tagLine: "A Tharra Twist on a Classic",
    img: `${BASE_IMAGE_URL}/cocktailCard/themoscowmuletwist.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPotatoIcon.webp`,

    description1:
      "Craving a refreshing twist on a timeless favorite? Look no further than the Moscow Mule Twist! This invigorating cocktail puts a distinctly Tharra spin on the classic. Imagine the iconic copper mug, gleaming with frosty condensation. Inside, Tharra Potato Vodka takes center stage, its smooth character perfectly complemented by the spicy kick of ginger beer. A splash of lime juice adds a burst of zesty tang, creating a flavor profile that's both familiar and exciting.",
    description2:
      "This Moscow Mule Twist isn't just delicious, it's easy to make and perfect for any gathering. Simply shake and strain over ice in your chilled copper mug. A final flourish of a lime wedge adds a touch of freshness and visual appeal.",
    ingredients: [
      { value: "2 oz", name: "Tharra Potato Vodka" },
      { value: "4 oz", name: "Ginger Beer" },

      { value: "1/2 oz", name: "Lime Juice" },
      { value: "", name: "Lime Wedge (for garnish)" }
    ],
    preparationListName: "Instructions",
    preparationList: [
      {
        value: "STEP 1",
        name: "Fill a copper mug with ice."
      },
      {
        value: "STEP 2",
        name: "Pour in Tharra vodka, ginger beer, and lime juice."
      },
      {
        value: "STEP 3",
        name: "Stir gently to combine."
      },
      {
        value: "STEP 4",
        name: "Garnish with a lime wedge."
      }
    ]
  },
  {
    id: 11,
    name: "Tharra Spritzer",
    linkName: "tharra-spritzer",
    category: "Potato",
    madeWith: "Tharra Potato Vodka",
    tagLine: "A Summer Escape in Every Sip",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharraspritzer.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPotatoIcon.webp`,

    description1:
      "Beat the heat and embrace the sunshine with the Tharra Spritzer, a refreshingly bubbly drink that captures the essence of a perfect summer day.",
    description2:
      "Imagine yourself poolside, the sun warm on your skin, and a cool glass of Tharra Spritzer in your hand. This light and invigorating cocktail is all about simplicity and pure refreshment. Smooth Tharra Potato Vodka mingles with the gentle fizz of sparkling water, creating a base that's light on the palate yet bursting with flavor. A squeeze of fresh lemon juice adds a touch of zesty brightness, while cucumber slices gently bob in the glass, offering a crisp, cooling element with every sip. Easy to make and endlessly delightful, the Tharra Spritzer is your passport to pure summer refreshment.",
    ingredients: [
      { value: "1.5 oz ", name: "Tharra Potato Vodka" },
      { value: "3 oz", name: "Sparkling Water" },

      { value: "1/2 oz ", name: "Fresh Lemon Juice" },
      { value: "", name: "Cucumber Slices (for garnish)" }
    ],
    preparationListName: "Instructions",
    preparationList: [
      {
        value: "STEP 1",
        name: "Fill a tall glass with ice."
      },
      {
        value: "STEP 2",
        name: "Pour in Tharra vodka, sparkling water, and lemon juice."
      },
      {
        value: "STEP 3",
        name: "Stir gently to combine."
      },
      {
        value: "STEP 4",
        name: "Garnish with cucumber slices."
      }
    ]
  },
  {
    id: 12,
    name: "Tharra Gimlet",
    linkName: "tharra-gimlet",
    category: "Potato",
    madeWith: "Tharra Potato Vodka",
    tagLine: "A Timeless Classic Gets a Modern Makeover",
    img: `${BASE_IMAGE_URL}/cocktailCard/tharragimlet.webp`,
    icon: `${BASE_IMAGE_URL}/cocktailCard/tharraPotatoIcon.webp`,

    description1:
      "Experience the Tharra Gimlet, a sophisticated twist on a beloved cocktail. This modern take features the smooth character of Tharra Potato Vodka, replacing the traditional gin. The result? A refreshingly zesty drink that's both familiar and exciting.",
    description2:
      "Imagine the iconic coupe glass filled with a vibrant emerald liquid. Each sip delivers a delightful balance of tartness from the fresh lime juice and sweetness from the simple syrup. The Tharra Potato Vodka adds a touch of smooth complexity,making this Gimlet anything but one-dimensional. Perfect for an intimate gathering or a quiet night in, the Tharra Gimlet is as easy to prepare as it is elegant to sip. A final flourish of a lime wedge intensifies the citrus aroma and adds a touch of visual appeal.",
    ingredients: [
      { value: "2 oz", name: "Tharra Potato Vodka" },
      { value: "3/4 oz", name: " Fresh lime juice" },

      {
        value: "1/2 oz",
        name: "simple syrup (or adjust to your taste preference)"
      },
      { value: "", name: "Lime wedge (for garnish)" }
    ],
    preparationListName: "Instructions",
    preparationList: [
      {
        value: "STEP 1",
        name: "Pop your coupe or martini glass in the freezer for a few minutes before making the drink. This will keep your cocktail colder for longer."
      },
      {
        value: "STEP 2",
        name: "Squeeze the fresh lime juice and have your simple syrup ready."
      },
      {
        value: "STEP 3",
        name: " Add the Tharra potato vodka, lime juice, and simple syrup to a cocktail shaker filled with ice. Shake well for about 20 seconds. You want the drink to be icy cold and well-combined."
      },
      {
        value: "STEP 4",
        name: " Strain the cocktail into your chilled glass. Garnish with a lime wedge by gently squeezing the oils from the rind over the drink before placing it on the rim."
      }
    ]
  }
];
